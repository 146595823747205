import React, { useEffect, useState } from "react";
import CustomLoader from "../CustomLoader";
import "../../assets/Css/UserProfile.css"
import config, { axiosbaseurl } from "../../config";
import secureLocalStorage from "react-secure-storage";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Modal } from "antd";

const EmailSettings = () => {

    const [notificationPopup, setNotificationPopup] = useState(false)
    // const [loading, setLoading] = useState(false)

    const navigate = useNavigate();
    const credentials = secureLocalStorage.getItem("credentials")

    useEffect(() => {
        if (!credentials) {
            navigate(config.appUrl)
        }
    }, [])

    return (
        <div>
            {/* {loading && <CustomLoader />} */}

            <div className='pt-90' style={{ backgroundColor: '#f4f4f4' }}>
                <div className="py-5 px-sm-5 px-4">
                    <div className="mb-4">
                        <span className="text-dark-blue fs-4 fw-500 "> Email Settings</span>
                        <div className="yellow-line"></div>
                    </div>
                    <div className="row justify-content-end">
                        <div className="col-md-12 pe-md-3">
                            <div className="account-container">
                                <div>
                                    <span className="text-dark-blue fs-5 fw-500">Collections</span>
                                    <div className="yellow-line"></div>
                                </div>
                                <div>
                                    <form action="">
                                        <div className="row align-items-end">
                                            <div className="col-md-8">
                                                <div className="profile-form-field mt-2">
                                                    <label htmlFor="">Name</label>
                                                    <input type="text" placeholder="Enter here" />
                                                </div>
                                            </div>
                                            <div className="col-md-4 text-md-end mt-md-0 mt-3">
                                                <div className="d-sm-flex justify-content-between">
                                                    <h6 className="paragraph mb-2 lh-sm">Collections Activity
                                                        <i className="bi bi-question-lg ms-2 px-1 py-0 rounded-3 bg-yellow text-white"></i> </h6>
                                                    <h6 className="paragraph mb-2 lh-sm">Listing Updates
                                                        <i className="bi bi-question-lg ms-2 px-1 py-0 rounded-3 bg-yellow text-white"></i> </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="account-container">
                                <div>
                                    <span className="text-dark-blue fs-5 fw-500">Saved Searches</span>
                                    <div className="yellow-line"></div>
                                </div>
                                <div>
                                    <form action="">
                                        <div className="row align-items-end">
                                            <div className="col-md-11">
                                                <div className="profile-form-field mt-2">
                                                    <label htmlFor="">Name</label>
                                                    <input type="text" placeholder="Enter here" />
                                                </div>
                                            </div>
                                            <div className="col-md-1 text-end">
                                                <h6 className="paragraph mb-2 lh-sm">Frequency</h6>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="account-container">
                                <div>
                                    <span className="text-dark-blue fs-5 fw-500">Saved Buildings</span>
                                    <div className="yellow-line"></div>
                                </div>
                                <div>
                                    <form action="">
                                        <div className="row align-items-end">
                                            <div className="col-md-11">
                                                <div className="profile-form-field mt-2">
                                                    <label htmlFor="">Name</label>
                                                    <input type="text" placeholder="Enter here" />
                                                </div>
                                            </div>
                                            <div className="col-md-1 text-end">
                                                <h6 className="paragraph mb-2 lh-sm">Frequency</h6>
                                            </div>

                                            <div className="col-12 mt-4">
                                                <div className="d-md-flex justify-content-between
                                                ">
                                                    <h6 className="mb-0 paragraph2">Greenwood's Saved Buildings</h6>
                                                    <div className="text-md-end mt-md-0 mt-3">
                                                        <select className="form-select rounded-0 border-0 ms-auto fw-500"
                                                            style={{
                                                                backgroundColor: '#f0f0f0',
                                                                fontSize: '14px', color: '#333', width: '140px'
                                                            }}>
                                                            <option value="Immediately">Immediately</option>
                                                            <option value="Daily">Daily</option>
                                                            <option value="Never">Never</option>
                                                        </select>

                                                        <h6 className="text-yellow mb-0 mt-4"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => setNotificationPopup(true)}
                                                        > Disable All Notifications</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/*  */}
                    </div>
                </div>
            </div>




            <Modal
                open={notificationPopup}
                title=""
                width={550}
                // className="career_page"
                centered
                onCancel={() => setNotificationPopup(false)}
                footer={<div className="footer-none"></div>}
            >
                <div className="mt-md-5 px-2">
                    <div >
                        <h2 className="fs-3 fw-600 text-dark-blue">Disable All Email notifications</h2>
                        <div className="pb-5 fs-6">
                            Are you sure you want to disable notifications for All collections and saves searches?</div>

                        <div className="text-center">
                            <button className="profile-btn me-3" style={{ backgroundColor: '#F04D4D' }} >
                                Yes, Disable
                            </button>
                            <button onClick={() => setNotificationPopup(false)}
                                style={{ boxShadow: '0px 0px 5px #999' }}
                                className="profile-btn bg-white logout-btn ">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}



export default EmailSettings