import React from 'react'
import structure1 from "../../../assets/BuyDetailPage/structure1.png"
import structure2 from "../../../assets/BuyDetailPage/structure2.png"
import structure3 from "../../../assets/BuyDetailPage/structure3.png"

const FloorPlans = () => {
    return (
        <div className="row">
            <div className="col-md-4 mb-md-0 mb-4">
                <div className="floorplan-card">
                    <img src={structure1} alt="" />
                    <div>
                        <p className='mb-1 text-dark-blue fw-600 fs-5 font-saira text-start'>1st Floor Plan</p>
                        <div className="yellow-line"></div>
                    </div>
                </div>
            </div>
            <div className="col-md-4 mb-md-0 mb-4">
                <div className="floorplan-card">
                    <img src={structure2} alt="" />
                    <div>
                        <p className='mb-1 text-dark-blue fw-600 fs-5 font-saira text-start'>3D View of 1st Floor</p>
                        <div className="yellow-line"></div>
                    </div>
                </div>
            </div>
            <div className="col-md-4 mb-md-0 mb-4">
                <div className="floorplan-card">
                    <img src={structure3} alt="" />
                    <div>
                        <p className='mb-1 text-dark-blue fw-600 fs-5 font-saira text-start'>3D View of Ground Floor</p>
                        <div className="yellow-line"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FloorPlans