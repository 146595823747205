import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import config, { axiosbaseurl } from '../../config'
import menusvg from "../../assets/Menu.png";

const FooterSideBar = ({ url_location }) => {

    const [footerSideMenu, setFooterSideMenu] = useState(false);

    const [pagePermissions, setPagePermissions] = useState([]);

    useEffect(() => {
        const controller = new AbortController();
        async function getPermittedPages() {
            try {
                const { data } = await axiosbaseurl.get(`/activePages`);
                if (data.success == true) {
                    setPagePermissions(data.data);
                }
            } catch (error) {
                console.log(error);
            }
        }
        getPermittedPages()

        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        setFooterSideMenu(false)
    }, [url_location?.pathname])

    const checkAccess = (id) => {
        // setFooterSideMenu(false)
        return pagePermissions?.find(page => page.id === id)?.status === 1
    }

    return (
        <>
            <li className="nav-item dropdown">
                <a
                    className="nav-link"
                    onClick={(e) => setFooterSideMenu(true)}
                >
                    <img
                        className="icon-size"
                        style={{ cursor: "pointer" }}
                        src={menusvg}
                        alt="menuicon"
                    />
                </a>
            </li>
            {
                footerSideMenu &&
                <div className={`footer-siderbar `}>
                    <div className='text-end'>
                        <i className='bi bi-x close-menu-btn text-yellow fs-1 lh-sm'
                            style={{ cursor: 'pointer' }}
                            onClick={() => setFooterSideMenu(false)}></i>
                    </div>
                    <div className='side-links'>
                        <div><NavLink to={`${config.appUrl}`} onClick={() => setFooterSideMenu(false)}>Home</NavLink></div>
                        {
                            checkAccess(1) &&
                            <div><NavLink to={`${config.appUrl}about-us`} onClick={() => setFooterSideMenu(false)}>About us</NavLink></div>
                        }
                        {
                            checkAccess(2) &&
                            <div><NavLink to={`${config.appUrl}management-team`} onClick={() => setFooterSideMenu(false)}>Management Team</NavLink></div>
                        }
                        <div><NavLink to={`${config.appUrl}proprenuers`} state={{ searchStr: '' }} onClick={() => setFooterSideMenu(false)}>
                            Propreneur</NavLink></div>
                        <div><NavLink to={`${config.appUrl}news`} onClick={() => setFooterSideMenu(false)}>News</NavLink></div>
                        <div><NavLink to={`${config.appUrl}blog`} onClick={() => setFooterSideMenu(false)}>Blogs</NavLink></div>
                        <div>
                            <NavLink to={`${config.appUrl}career`} onClick={() => setFooterSideMenu(false)}>Careers</NavLink>
                        </div>
                        <div>
                            <NavLink to={`${config.appUrl}contact-us`} onClick={() => setFooterSideMenu(false)}>Contact Us</NavLink>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default FooterSideBar