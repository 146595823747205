import React, { useEffect, useState } from "react";
import ReactDatamaps from "react-india-states-map";

const AuthorityMap = ({ allStates, activeState, stateOnClick, setactiveState }) => {
    
    const [stateLists, setStateLists] = useState([]);

    useEffect(() => {
        // set Active State
        const defaultState = allStates.find(item => item.id === 3)
        setactiveState({
            data: defaultState?.id,
            name: defaultState?.state_name
        })

        // set All States
        const statesData = {};
        allStates?.forEach(item => {
            statesData[`${item.state_name}`] = {
                value: item.id
            };
        });

        setStateLists(statesData)
    }, [allStates])

    return (
        <ReactDatamaps
            regionData={stateLists}
            mapLayout={{
                hoverTitle: "Count",
                noDataColor: "#f6cb54",
                borderColor: "#132845",
                hoverColor: "#132845",
            }}
            hoverComponent={({ value }) => {
                return (
                    <>
                        <p>{value.name}</p>
                    </>
                );
            }}
            onClick={stateOnClick}
            activeState={activeState}
        />
    );
};

export default AuthorityMap;
