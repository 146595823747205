import React, { useEffect, useState } from 'react'

// Assets
import "../../../assets/Css/Career.css"
import { axiosbaseurl } from '../../../config'
import CustomLoader from '../../CustomLoader'


const PrivacyPolicies = () => {

    const [privacy, setPrivacy] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true);
        axiosbaseurl
            .get("/privacyPolicy")
            .then((res) => {
                setPrivacy(res.data.data.content);
            })
            .catch((error) => console.log(error))
            .finally(() => setLoading(false));

        return () => {
            setPrivacy("")
            controller.abort();
        };
    }, []);

    return (
        <div>
            {loading && <CustomLoader />}

            <section className='pt-90'>

                <div className='px-sm-5 px-4 my-5'>
                    <div className="row sitemap-section" >
                        <div className="common-height col-1 d-md-block d-none position-relative ">
                            <div className='bg-yellow h-100'>
                                <div className='key-icon bg-dark-blue d-md-block d-none'></div>
                            </div>
                        </div>

                        <section className="common-height col-md-11 col-12  pt-4 px-4 editor_main_content pb-5"
                            style={{ backgroundColor: '#faf8f4' }}>
                            {/* Static Heading */}
                            <div className="mb-20">
                                <h1 className="bd-header-overview mb-0"> <span className='text-yellow'>Privacy</span> Policy</h1>
                                <div className="yellow-line"></div>
                            </div>
                            {/* ======================== */}
                            <div
                                dangerouslySetInnerHTML={{ __html: privacy }}
                                className="editor_main_content "
                            />
                        </section>

                    </div>
                </div>

            </section>

        </div>
    )
}


export default PrivacyPolicies