import React, { useEffect, useState } from 'react'
import config from '../../config';
import { useNavigate } from 'react-router-dom';

const NavSearchBar = () => {

    const [searchString, setSearchString] = useState('');

    const navigate = useNavigate()
    // const [allAddress, setALLAddress] = useState([]);
    // const [neighbourhoods, setNeighbourhoods] = useState([]);
    // const [searchSuggestions, setSearchSuggestions] = useState([]);

    // useEffect(() => {
    //     if (homePageData) {
    //         setAllCities(homePageData?.cities);
    //         setNeighbourhoods(homePageData?.localitities);

    //         const addresses = []
    //         homePageData?.page_data?.address?.forEach(add => {
    //             if (!addresses.some(item => item?.address === add?.address))
    //                 addresses.push(add)
    //         })

    //         setALLAddress(addresses)
    //     }

    //     return () => {
    //         setAllCities([])
    //         setNeighbourhoods([])
    //     };
    // }, [homePageData]);

    const handleStartSearch = (e) => {
        e.preventDefault()
        navigate(`${config.appUrl}global-search?search=${searchString}`)
    }

    return (
        <div className="bd-search-navbar prop-responsive d-md-block d-none">
            <form onSubmit={handleStartSearch}>
                <div
                    className="input-group main-searh-input"
                >
                    <input
                        type="text"
                        className="form-control font-saira ps-3"
                        // placeholder="City, Neighbourhood, Address, School, Zip, Agent, ID"
                        placeholder='City, Neighbourhood, Address, Zipcode..'
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        required
                        style={{
                            fontSize: "12px",
                            border: "none",
                            color: "rgba(108, 117, 125,0.6)",
                        }}
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)}
                    />
                    <div className="input-group-append">
                        <button
                            className="btn btn-outline-secondary "
                            type="submit"
                            // onClick={handleSearch}
                            style={{
                                paddingTop: "2px",
                                backgroundColor: "#f5c954",
                                color: "white",
                                border: "none",
                                borderBottomLeftRadius: "0px",
                                borderTopLeftRadius: "0px",
                            }}
                        // onClick={() => }
                        >
                            <i className="fa fa-search"></i>
                        </button>
                    </div>
                </div>
            </form>
        </div>

    )
}

export default NavSearchBar