import React from 'react'

const VirtualTour = ({ currentShowCase }) => {
    return (
        <section >
            <div style={{ borderRadius: '14px', overflow: 'hidden' }}>
                <video controls
                    // src="https://videos.pexels.com/video-files/7347891/7347891-uhd_2560_1440_25fps.mp4"
                    src={currentShowCase ? currentShowCase[0]?.upload_name : ''}
                    className='w-100'
                    style={{ height: '100vh' }}
                ></video>
            </div>
        </section>
    )
}

export default VirtualTour