import React, { useEffect, useState } from 'react'

import { MapContainer, TileLayer, Marker, Popup, useMapEvent, useMap } from 'react-leaflet';
import * as L from "leaflet";
import 'leaflet/dist/leaflet.css';

import icon1 from "../../assets/footer/pointer1.png"
import icon2 from "../../assets/footer/pointer.png"
import icon3 from "../../assets/footer/pointer_red.png"


const customMarkerIcon = L.icon({
    iconUrl: icon1,
    iconSize: [20, 30],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});

const customMarkerIcon2 = L.icon({
    iconUrl: icon2,
    iconSize: [16, 25],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});

const customMarkerIcon3 = L.icon({
    iconUrl: icon3,
    iconSize: [15, 22],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});

const countryStyle = {
    color: 'black',
    opacity: 1,
    height: '100%',
    width: '100%'
};

const defaultPosition = [23, 80.962883]

// Custom hook to listen to zoom events and display areas/localities
const ZoomListener = ({ setZoomLevel }) => {
    const map = useMapEvent('zoomend', () => {
        const currentZoom = map.getZoom();
        setZoomLevel(currentZoom);
    });
    return null;
};

// Function to handle map zoom and center
const ChangeView = ({ center, zoom }) => {
    const map = useMap();
    map.flyTo(center, zoom, { animate: true, duration: 1 }); // Smooth zoom
    return null;
};

const IndianMap = ({
    headquarters,
    regionalOffice,
    multiLocation
}) => {

    const [zoomLevel, setZoomLevel] = useState(4)
    const [position, setPosition] = useState(defaultPosition)

    const handleClickLocation = (curr_pos) => {
        setZoomLevel(prev => prev < 11 ? 11 : prev)
        setPosition(curr_pos)
    }

    return (
        <>
            <div id='map' className='w-100 h-100 '>
                <MapContainer
                    center={position}
                    zoom={zoomLevel}
                    zoomControl={true}
                    scrollWheelZoom={true}
                    doubleClickZoom={false}
                    touchZoom={false}
                    boxZoom={false}
                    style={countryStyle}
                >
                    {/* 22.3478 73.1740 */}
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />

                    {/* Change the map's view dynamically when city/area is clicked */}
                    <ChangeView center={position} zoom={zoomLevel} />

                    <ZoomListener
                        zoomLevel={zoomLevel}
                        setZoomLevel={setZoomLevel} />

                    {multiLocation && multiLocation?.map((location, index) => (
                        <Marker key={index}
                            position={[location.latitude, location.longitude]}
                            icon={customMarkerIcon}
                            eventHandlers={{
                                click: () => handleClickLocation([location.latitude, location.longitude])
                            }}
                        >
                            <Popup
                                autoPan={false}
                                closeButton={false}
                                offset={[135, 100]}
                            >
                                <PopUpBox location={location} />
                            </Popup>
                        </Marker>
                    ))}

                    {headquarters && headquarters?.map((location, index) => (
                        <Marker key={index}
                            position={[location.latitude, location.longitude]}
                            icon={customMarkerIcon2}
                            eventHandlers={{
                                click: () => handleClickLocation([location.latitude, location.longitude])
                            }}
                        >
                            <Popup
                                autoPan={false}
                                closeButton={false}
                                offset={[135, 100]}
                            >
                                <PopUpBox location={location} />
                            </Popup>
                        </Marker>
                    ))}

                    {regionalOffice && regionalOffice?.map((location, index) => (
                        <Marker key={index}
                            position={[location.latitude, location.longitude]}
                            icon={customMarkerIcon3}
                            eventHandlers={{
                                click: () => handleClickLocation([location.latitude, location.longitude])
                            }}
                        >
                            <Popup
                                autoPan={false}
                                closeButton={false}
                                offset={[135, 100]}
                            >
                                <PopUpBox location={location} />
                            </Popup>
                        </Marker>
                    ))}
                </MapContainer>
            </div>
        </>
    )
}

export default IndianMap

const PopUpBox = ({ location }) => {
    return (
        <div className=" ">
            <div>
                <h6 className='font-saira fw-600'>{location?.company}</h6>
                <p className="my-1 font-saira">{location?.address}</p>
            </div>
            <div className='mt-2'>
                <span className="pr-10 text-dark-blue">
                    <i className='bi bi-envelope'></i>
                </span>
                <a href={`mailto:${location?.email}`}
                    className='bd-info-loca font-saira text-decoration-none fw-500'>{location?.email}</a>
            </div>
            <div className='mt-1'>
                <span className="pr-10 text-dark-blue">
                    <i className='bi bi-telephone'></i>
                </span>
                <a href={`tel:${location?.phone}`}
                    className='bd-info-loca font-saira text-decoration-none fw-500'>{location?.phone}</a>
            </div>
        </div>
    )
}