import React, { useEffect, useRef, useState } from "react";
import { faArrowRight, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";
import config from "../../config";
import Slider from "react-slick";

function SearchingCities({ topCityData, ReraData, heading }) {

  const [AllCities, setAllCities] = useState([])
  const [active_cities, setActiveCities] = useState(2)

  useEffect(() => {
    let filter_type = 0;
    const final_city = topCityData?.map((city) => {

      if (filter_type < 3)
        city.filter_type = 1

      if (filter_type >= 3 && filter_type < 15)
        city.filter_type = 2

      if (filter_type >= 15)
        city.filter_type = 3


      filter_type++
      return city
    })
    setAllCities(final_city)

  }, [topCityData])


  const sliderRef = useRef();

  const reraSettings = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 3,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    mobileFirst:true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="pd-cities-back2 ">
      <div className="pt-40 pb-40 bd-cities-back ">
        <div className=" py-md-5 auto-container">
          <div>
            <div className="bd-main-service-section ">
              <div className="bd-dashed-line mr-10">
              </div>
              <div>
                <p className="bd-service-first-heading">{heading?.title}</p>
              </div>
              <div>
                <div className="bd-dashed-line ml-10"></div>
              </div>
            </div>
          </div>
          <div className="textCenter">
            <h2 className="bd-service-main-heading">{heading?.subtitle}</h2>
          </div>
          <div className="my-4 py-2">
            <div className="d-flex w-100 flex-md-row flex-column justify-content-center align-items-center home-cities">

              <div className="px-4 text-md-center top-city-tab">
                <h5 className={`position-relative my-md-0 my-3 ${active_cities == 1 && 'active-city-tab'}`}
                  onClick={() => setActiveCities(1)}>Rentals House</h5>
              </div>

              <div className="px-4 text-md-center top-city-tab">
                <h5 className={`position-relative my-md-0 my-3 ${active_cities == 2 && 'active-city-tab'}`}
                  onClick={() => setActiveCities(2)}>Real Estate In Cities</h5>
              </div>

              <div className="px-4 text-md-center top-city-tab">
                <h5 className={`position-relative my-md-0 my-3 ${active_cities == 3 && 'active-city-tab'}`}
                  onClick={() => setActiveCities(3)}>Affordable House</h5>
              </div>
            </div>
          </div>
          <div className=" bd-responsive-cities">
            <div className="row justify-content-md-start justify-content-center mx-0">
              {
                // ?.filter(item => item.filter_type == active_cities)
                AllCities?.map((city, i) => {
                  return (
                    <div key={city?.id} className="col-xl-3 col-md-4 col-sm-6 col-11 mt-4">
                      <div className="city-image-box top-city-box">
                        <img loading="lazy" src={city.image} alt={city?.city_name} className="w100"  />
                        <Link to={`${config.appUrl}propuser-locality/${city?.id}`}>
                          <div className="city_name">{city?.city_name}</div>
                        </Link>
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <div>
              <div className="textRight mr-15 pt-20 mt-4">
                <Link to={`${config.appUrl}our-cities`} className="bd-SeeAll-btn py-2 text-decoration-none"> See All <FontAwesomeIcon icon={faArrowRight} className="pl-10" /></Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Rera Slider */}
      <div>
        <div className="pt-15 pb-15 prop-responsive" style={{ backgroundColor: '#F8F9FB' }}>
          <div className="display-flex justify-content-center align-items-center px-4">
            <div className="pd-first-city-arrow text-center fs-4"
              onClick={() => sliderRef?.current?.slickPrev()}>
              <FontAwesomeIcon icon={faChevronLeft} className="text-dark-blue" /></div>
            <div className="rera-slider mx-md-3 mx-2">
              <Slider ref={sliderRef} {...reraSettings}>
                {
                  ReraData?.map((rera) => {
                    return (
                      <div className="pd-first-city text-center" key={rera.id}>{rera.state_name} RERA: <span>{rera.rera_no}</span></div>
                    )
                  })
                }
              </Slider>
            </div>
            <div className="pd-first-city-arrow text-center fs-4"
              onClick={() => sliderRef?.current?.slickNext()}
            > <FontAwesomeIcon icon={faChevronRight} className="text-dark-blue" /></div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default SearchingCities;
