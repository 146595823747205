import React, { useEffect, useState } from 'react'
import { createContext } from 'react';
import { axiosbaseurl } from './config';
import secureLocalStorage from 'react-secure-storage';

export const AppGlobalContext = createContext("");

const GlobalContext = ({ children }) => {

    const [current_location, setCurrentLocation] = useState(null);
    const [cities_options, setCitiesOption] = useState([]);
    const [join_heading, setJoin_heading] = useState([]);
    const [boardingData, setBoardingData] = useState(null);
    const [all_website_data, setAllWebsiteData] = useState(null);

    useEffect(() => {
        const controller = new AbortController();
        async function getCities() {
            try {
                const { data } = await axiosbaseurl.get(`/cities`);

                if (data.success == true) {
                    setCitiesOption(data.data)
                    setJoin_heading({
                        heading: {
                            title: data.titles[0]?.title.replaceAll('(', '<span class="fw-bold">')?.replaceAll(')', '</span>'),
                            subtitle: data.titles[0]?.subtitle.replaceAll('(', '<span class="font-saira">')?.replaceAll(')', '</span>')
                        },
                        bg_image: data.titles[1]?.subtitle
                    })
                }

                // getting boarding data
                const response = await axiosbaseurl.get(`/onboarding`);
                // console.log('onboarding', response.data.data)
                setBoardingData(response.data.data)

                // get all website data for global searching
                const all_data = await fetchAllData()
                setAllWebsiteData(all_data)

            } catch (error) {
                console.log(error);
            }
        }
        getCities();
        return () => {
            controller.abort()
        };
    }, []);

    const fetchAllData = async () => {
        const about_data = await axiosbaseurl.get('/aboutPage');
        const career_data = await axiosbaseurl.get('/careerPage');
        const sell_data = await axiosbaseurl.get('/ExperienceData');
        const blog_data = await axiosbaseurl.get('/blogs/0');
        const news_data = await axiosbaseurl.get('/news/0');
        const propreneur_data = await axiosbaseurl.get('/proprenuers');
        const property_data = await axiosbaseurl.post('/properties', { user_id: 0 });

        return {
            about_data: about_data?.data,
            career_data: career_data?.data,
            sell_data: sell_data?.data,
            blog_data: blog_data?.data,
            news_data: news_data?.data,
            propreneur_data: propreneur_data?.data,
            property_data: property_data?.data
        }
    }


    // ip location functionality

    const APIkey = process.env.REACT_APP_API_KEY

    // checking browser has access to geolocation or not
    useEffect(() => {
        if (!secureLocalStorage.getItem("ip_location")) {
            if (navigator.geolocation) {
                navigator.permissions
                    .query({ name: "geolocation" })
                    .then(function (result) {
                        if (result.state === "granted") {
                            navigator.geolocation.getCurrentPosition(success, errors, options);
                        } else if (result.state === "prompt") {
                            navigator.geolocation.getCurrentPosition(success, errors, options);
                        } else if (result.state === "denied") {
                            //If denied then you have to show instructions to enable location
                        }
                    });
            } else {
                console.log("Geolocation is not supported by this browser.");
            }
        } else {
            setCurrentLocation(secureLocalStorage.getItem("ip_location"));
        }
    }, []);

    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };

    function success(pos) {
        var crd = pos.coords;
        if (crd)
            getLocationInfo(crd.latitude, crd.longitude)
    }

    function errors(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    // getting location through latitude & longitude
    function getLocationInfo(latitude, longitude) {
        const url = `https://api.opencagedata.com/geocode/v1/json?q=${latitude},${longitude}&key=${APIkey}`;
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);
                if (data.status.code === 200) {
                    const city_name = data.results[0]?.components?.state_district
                    console.log('Your location : ', city_name)
                    setCurrentLocation(city_name)
                    secureLocalStorage.setItem("ip_location", city_name)
                } else {
                    console.log("Reverse geolocation request failed.");
                }
            })
            .catch((error) => console.error(error));
    }


    return (
        <AppGlobalContext.Provider value={{ current_location, cities_options, join_heading, boardingData, all_website_data }}>
            {children}
        </AppGlobalContext.Provider>
    )
}

export default GlobalContext
