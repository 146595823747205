import React, { useEffect, useRef, useState } from "react";

import Slider from "react-slick";
// slick slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Testimonials({ testimonialData = [], heading }) {

  const sliderRef = useRef();
  const videoRefs = useRef([]);

  // Populate the refs array with useRef for each element in `data`
  useEffect(() => {
    if (testimonialData.length > 0) {
      videoRefs.current = testimonialData.map((_, i) => videoRefs.current[i] || React.createRef());
    }
  }, [testimonialData]);


  const blogsSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div>
        <div className="pt-60 pb-80">
          <div>
            <div className="bd-main-service-section ">
              <div className="bd-dashed-line mr-10"></div>
              <div>
                <p className="bd-service-first-heading">{heading?.title}</p>
              </div>
              <div>
                <div className="bd-dashed-line ml-10"></div>
              </div>
            </div>
            <div className="pb-30">
              <div className="textCenter">
                <h2 className="bd-service-main-heading">{heading?.subtitle}</h2>
              </div>
            </div>
          </div>
          <div className="container-4 bd-responsive-cities">
            <Slider ref={sliderRef} {...blogsSettings}>
              {testimonialData?.map((review, index) => {
                return (
                  <VideoCard key={review.id} review={review} />
                );
              })}
            </Slider>
            <div className="slider-btns text-end mt-4">
              <button onClick={() => sliderRef?.current?.slickPrev()}>
                <i className="bi bi-arrow-left"></i>
              </button>
              <button onClick={() => sliderRef?.current?.slickNext()}>
                <i className="bi bi-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const VideoCard = ({ review }) => {

  const [videoPlaying, setVideoPlaying] = useState(false)
  const videoRef = useRef(null)

  const handlePlayVideo = () => {
    if (videoPlaying) {
      setVideoPlaying(false);
      videoRef?.current.pause();
    } else {
      setVideoPlaying(true);
      videoRef?.current.play();
    }
  };

  const img_types = ['jpg', 'jpeg', 'png', 'avif'];

  const checkIsImage = (url = "") => {
    const extension = url?.split('.')
    return img_types.includes(`${extension?.slice(-1)}`)
  }


  return (
    <div
      className="d-md-flex testimonial-card"
    >
      <div className="w-50 responsive-class">
        <div className="h-100 position-relative ">

          {
            checkIsImage(review?.image) ?
              <img
                src={review?.image}
                className="testimonial-img h-100"
              /> :
              <>
                <video
                  ref={videoRef}
                  src={review?.image}
                  className='h-100 w-100 bg-dark'
                />

                {/* video control buttons */}
                <button className="review-play-btn"
                  onClick={handlePlayVideo}
                >
                  <i
                    className={`bi bi-${videoPlaying ? "pause" : "play"}-fill`}
                  ></i>
                </button>

                <button className="review-volume-btn">
                  <i className="bi bi-volume-up-fill"></i>
                </button>

              </>
          }

        </div>

      </div>
      <div className="w-50 responsive-class">
        <div className="bd-slider-area  ">
          <div>
            <i
              className="bi bi-quote display-3 text-yellow d-inline-block"
              style={{ transform: "translateX(-10px)", lineHeight: 0.2 }}
            ></i>
            <p
              className="text-white lh-lg mb-0"
              style={{
                height: "148px",
                overflow: "auto",
                scrollbarWidth: "none",
              }}
              dangerouslySetInnerHTML={{ __html: review?.testimonial_comments }}
            />

            <div>
              <h5 className="bd-testimonial-head text-capitalize fs-2 mt-5">
                {review?.name}
              </h5>
              <p className="text-white fw-500">{review?.profession} </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonials;
