import React, { useContext, useEffect } from 'react'
import propuserlogo from '../../assets/Propusers_logo-White.png';
import { useState } from 'react';

import "../../Styles/Comman.css"
import { axiosbaseurl } from '../../config';
import toast, { Toaster } from 'react-hot-toast';
import { customToastOptions } from '../../Styles/popup_style';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import config from '../../config';
import secureLocalStorage from 'react-secure-storage';
import { AppGlobalContext } from '../../GlobalContext';

const CreateAccount = () => {

  // const classes = useStyle()
  const [register_data, setRegisterData] = useState({ contact: "+91 " })
  const [loading, setLoading] = useState(false)
  const [show_pwd, setShowPwd] = useState(false)
  const [show_pwd2, setShowPwd2] = useState(false)
  const [errors, setErrors] = useState({});

  const { boardingData } = useContext(AppGlobalContext);


  useEffect(() => {
    if (Object.keys(errors)?.length)
      setErrors({})
  }, [register_data])

  const navigate = useNavigate()
  const locationData = useLocation()

  useEffect(() => {
    if (!locationData?.state) {
      navigate(`${config.appUrl}create-account`)
    }
  }, [])

  const validateForm = () => {
    const newErrors = {};

    if (!register_data?.name) {
      newErrors.name = 'Name is required';
    }

    if (!register_data?.email) {
      newErrors.email = 'Email is required';
    }
    else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(register_data?.email)) {
      newErrors.email = 'Invalid email format';
    }

    if (!register_data?.contact) {
      newErrors.contact = 'Phone number is required';
    } else if (register_data?.contact.length > 14 || register_data?.contact.length < 14) {
      newErrors.contact = 'Phone number must be of 10 digits';
    }

    if (!register_data?.city) {
      newErrors.city = 'City is required';
    }

    if (!register_data?.password) {
      newErrors.password = 'Password is required';
    }

    if (!register_data?.confirm_password) {
      newErrors.confirm_password = 'Confirm password is required';
    }
    else if (register_data?.confirm_password !== register_data?.password) {
      newErrors.confirm_password = 'Incorrect Confirm Password';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRegisterUser = async (e) => {
    e.preventDefault()
    if (validateForm()) {
      try {
        setLoading(true)

        const user_data = {
          ...register_data,
          type: locationData?.state?.registerType
        }

        const response = await axiosbaseurl.post('/signUp', user_data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          }
        })

        if (response.data.success == false && response.data.data == "email already in use") {
          toast.error('Email Already Registered!');
        }

        if (response.data.success == true) {
          toast.success('Otp Sent Successfully')

          const temp = {
            otp: response.data.otp,
            user_id: response.data.id,
            email: register_data?.email
          }
          secureLocalStorage.setItem('temp_data', temp)

          setTimeout(() => {
            navigate(`${config.appUrl}create-account-otp`)
          }, 2000);

          setRegisterData(null)
        }

        setLoading(false)

      } catch (error) {
        console.log(error)
        toast.error('Something went wrong !!');
        setLoading(false)
      }
    }
  }

  function setFormData(e) {

    let value = e.target.value;
    if (e.target.name === "contact") {
      if (value.startsWith('+91 ')) {
        value = value
      } else {
        value = ('+91 ' + value.replace(/^(\+91\s*)/, ''));
      }
    }

    setRegisterData(prev => ({ ...prev, [e.target.name]: value }))
  }

  return (
    <div>
      <Toaster position="top-right" reverseOrder={false} toastOptions={customToastOptions} />

      <div
        className='bg-image py-4 row justify-content-end align-items-center mx-0'
        style={{
          marginTop: "90px",
          backgroundImage: `linear-gradient(to right, transparent 20%, #374053), url(${boardingData?.signup?.image})`
        }}
      >
        <div className="col-xl-6 col-lg-7 col-md-8">

          <div className='form-container'>
            <div className='top-form-section'>
              <img src={propuserlogo} alt=""></img>
              <h4 className='py-4'>{boardingData?.register?.title} -
                <span className='text-white'> {locationData?.state?.registerType}</span></h4>
            </div>

            <form onSubmit={handleRegisterUser}>
              <div className="row">
                <div className="col-md-6 mb-4 pb-1">
                  <div className="form-floating">
                    <input type='text' name='name'
                      onChange={setFormData}
                      value={register_data?.name}
                      onKeyPress={(event) => {
                        if (/[0-9]/.test(event.key))
                          event.preventDefault();
                      }}
                      className={'form-control form-field'}
                      placeholder="Full Name"></input>

                    <label htmlFor="name">Full Name</label>
                  </div>
                  {errors?.name && <span className='text-danger badge'>{errors?.name}</span>}

                </div>

                <div className="col-md-6 mb-4 pb-1">

                  <div className="form-floating">
                    <input type='text' name='email'
                      onChange={setFormData}
                      value={register_data?.email}
                      className={'form-control form-field'}
                      placeholder="Email"></input>

                    <label htmlFor="name">Email</label>
                  </div>
                  {errors?.email && <span className='text-danger badge'>{errors?.email}</span>}
                </div>

                <div className="col-md-6 mb-4 pb-1">

                  <div className="form-floating">
                    <input type='text' name='contact'
                      onChange={setFormData}
                      value={register_data?.contact}
                      maxLength={14}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key))
                          event.preventDefault();
                      }}
                      className={'form-control form-field text-dark'}
                      placeholder="Enter Phone No"></input>

                    <label htmlFor="name">Enter Phone No</label>
                  </div>
                  {errors?.contact && <span className='text-danger badge'>{errors?.contact}</span>}
                </div>

                <div className="col-md-6 mb-4 pb-1">

                  <div className="form-floating">
                    <input type='text' name='city'
                      onChange={setFormData}
                      value={register_data?.city}
                      className={'form-control form-field'}
                      placeholder="City"></input>

                    <label htmlFor="name">City</label>
                  </div>
                  {errors?.city && <span className='text-danger badge'>{errors?.city}</span>}

                </div>

                <div className="col-md-6 mb-4 pb-1">

                  <div className="form-floating">
                    <input type={`${show_pwd ? 'text' : 'password'}`} name='password'
                      onChange={setFormData}
                      value={register_data?.password}
                      className={'form-control form-field'}
                      placeholder="Enter Password"></input>

                    <label htmlFor="name">Enter Password</label>
                    <i className={`bi bi-eye${show_pwd ? '-slash' : ''}-fill pass-eye`} onClick={() => setShowPwd(!show_pwd)}></i>
                  </div>
                  {errors?.password && <span className='text-danger badge'>{errors?.password}</span>}
                </div>

                <div className="col-md-6 mb-4 pb-1">

                  <div className="form-floating">
                    <input type={`${show_pwd2 ? 'text' : 'password'}`} name='confirm_password'
                      onChange={setFormData}
                      value={register_data?.confirm_password}
                      className={'form-control form-field'}
                      placeholder="Confirm Password"></input>

                    <label htmlFor="name">Confirm Password</label>
                    <i className={`bi bi-eye${show_pwd2 ? '-slash' : ''}-fill pass-eye`} onClick={() => setShowPwd2(!show_pwd2)}></i>
                  </div>
                  {errors?.confirm_password && <span className='text-danger badge'>{errors?.confirm_password}</span>}

                </div>

                <p
                  className='text-white text-center mt-3'
                  dangerouslySetInnerHTML={{ __html: boardingData?.register?.subtitle }}
                />
                {/* <p className='text-white text-center mt-3' style={{ fontSize: '14px' }}> By Continuing, you agree to propusers.
                  <Link to={`${config.appUrl}terms-and-condition`} className='text-yellow text-decoration-none'> Terms of service </Link>
                  &
                  <Link to={`${config.appUrl}privacy-policy`} className='text-yellow text-decoration-none'> Privacy Policy </Link>
                </p> */}
              </div>

              <button className='btn-blue' type='submit' disabled={loading} >
                {
                  loading ? "Submitting..." : "Submit"
                }
              </button>
            </form>
          </div>
        </div>
      </div>



    </div>
  )
}

export default CreateAccount