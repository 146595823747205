import React from "react";
import AgentJoinForm from "./AgentJoinForm";

const AgentForm01 = () => {

  return (
    <div>
      <AgentJoinForm />
    </div>
  );
};

export default AgentForm01;
