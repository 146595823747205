import React, { useEffect, useRef, useState } from "react";
import onBoardImg from "../../assets/Onboarding Process.png";
import config, { axiosbaseurl } from "../../config";
import { Link } from "react-router-dom";

import "../../assets/Css/JoinPropnuer.css";
import CustomLoader from "../CustomLoader";
import AgentJoinForm from "../AgentForm/AgentJoinForm";

const JoinPropreneurNew = () => {
    const [allData, setAllData] = useState({});
    const [loading, setLoading] = useState(false);
    const [activeAccordian, setActiveAccordian] = useState(1);

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true);
        axiosbaseurl
            .get(`/join_as_prop`)
            .then((response) => {
                // console.log(response?.data);
                setAllData(response?.data);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            setAllData({})
            controller.abort();
        };
    }, []);

    // colors & icons for cards
    const advantageDesigns = [
        {
            color: '#675388',
            icon: 'person-check-fill'
        },
        {
            color: '#B94EA0',
            icon: 'megaphone-fill'
        },
        {
            color: '#E05D5D',
            icon: 'mortarboard-fill'
        },
        {
            color: '#F59E54',
            icon: 'lightbulb-fill'
        },
        {
            color: '#F5C954',
            icon: 'headset'
        },
        {
            color: '#85B126',
            icon: 'cup-hot-fill'
        },
        {
            color: '#45AA93',
            icon: 'chat-right-dots-fill'
        },
        {
            color: '#26B6E2',
            icon: 'graph-up-arrow'
        }
    ]

    const { real_estate_sales, lead_magnet, propreneur_advantage,
        diversity_of_propreneurs, key_benefits, at_glance, faq } = allData;

    return (
        <div>
            {loading && <CustomLoader />}

            {/* Real Estate  */}
            <section className="mt-90">
                <div className="join-first-sec-container">
                    <div className="row align-items-center flex-md-row flex-column-reverse justify-content-between mx-0 position-relative overflow-hidden">
                        <div className="col-md-4 ">
                            <div className="mt-md-0 mt-5 ps-4">
                                <h1 className="display-5 fw-bold text-dark-blue lh-sm">{real_estate_sales?.title}</h1>
                                <p className="my-4 pt-2 pe-md-5 fw-normal"
                                    dangerouslySetInnerHTML={{ __html: real_estate_sales?.subtitle }}
                                />
                            </div>
                        </div>

                        <div className="col-md-8 position-relative  pb-md-5 pe-md-0 ">
                            <div className="bg-yellow join-first-sec-image">
                                <img src={real_estate_sales?.url} alt="" />
                            </div>

                            {/* right side circles */}
                            <div className="rounded-circle position-absolute real-first-dark-circle"></div>
                            <div className="rounded-circle position-absolute real-second-yellow-circle"></div>
                            <div className="rounded-circle position-absolute real-third-blue-circle"></div>
                            <div className="rounded-circle position-absolute real-fourth-yellow-circle"></div>
                        </div>
                        {/* left side circles */}
                        <div className="left-side-circles">
                            <div className="rounded-circle position-absolute real-fifth-light-circle"></div>
                            <div className="rounded-circle position-absolute real-sixth-dark-circle"></div>
                            <div className="rounded-circle position-absolute real-seventh-yellow-circle"></div>
                            <div className="rounded-circle position-absolute real-eighth-blue-circle"></div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Lead Magnet */}
            <section className="py-md-5">
                <div className="py-md-5 auto-container-lg">
                    <div className="d-md-flex">
                        <div>
                            <h3 className="yellow-line-heading verticle-heading text-dark-light me-0 d-md-block d-none">
                                {lead_magnet?.title}
                            </h3>
                            <div className="d-md-none d-block ">
                                <h2 className="text-dark-blue fw-bold mt-5 pt-5 display-6">{lead_magnet?.title}</h2>
                                <div className="yellow-line mb-5"></div>
                            </div>
                        </div>
                        <div style={{ flex: 1 }} className="ps-md-4">
                            <div className="row ps-md-5">

                                {
                                    lead_magnet?.divs?.map((leaData, index) => {
                                        return (
                                            (index % 2 === 0) ?
                                                <LeadMagnetLeft magnetData={leaData} key={leaData.id} /> :
                                                <LeadMagnetRight magnetData={leaData} key={leaData.id} />
                                        )
                                    })
                                }

                            </div>

                            <div className="text-center mt-5">
                                <Link
                                    to={`${config.appUrl}agent-registration`}
                                    className="sky-blue-btn"
                                >
                                    Join Now
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* propreneur glance */}
            <section className="pt-5 pb-5 w-100 overflow-hidden">
                <div className="pb-md-4 auto-container-lg">
                    <div className="row ">
                        <div className="col-md-5 position-relative">
                            <div>
                                <div className="rounded-circle position-absolute join-ninth-light-circle"></div>
                                <div className="rounded-circle position-absolute join-tenth-light-circle"></div>

                                <div className="bd-responsive-section pl-100 mb-md-4 pe-md-0 pe-3">
                                    <div className="main-row resp-section-advant pt-5">
                                        <div className="bd-outer pr-15">
                                            <div className="bd-advan-border"></div>
                                        </div>
                                        <div>
                                            <h2 className="text-dark-blue fw-bold">{at_glance?.title}</h2>
                                            <p className="text-444" style={{ fontSize: '15px' }}
                                                dangerouslySetInnerHTML={{ __html: at_glance?.description }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="position-relative ">
                                    <div className="join-round-image join-small-round-image">
                                        <img src={at_glance?.url} alt="" />
                                    </div>
                                    <div className="join-circle-connector"> </div>
                                    <div className="rounded-circle position-absolute join-eighth-dark-blue-circle"></div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-7 position-relative">
                            <div className="join-round-image join-big-round-image">
                                <img src={at_glance?.img} alt="" />

                            </div>
                            <div>
                                <div className="rounded-circle position-absolute join-first-purple-circle"></div>
                                <div className="rounded-circle position-absolute join-second-yellow-circle"></div>
                                <div className="rounded-circle position-absolute join-third-purple-circle"></div>
                                <div className="rounded-circle position-absolute join-fourth-light-circle"></div>
                                <div className="rounded-circle position-absolute join-fifth-yellow-circle"></div>
                                <div className="rounded-circle position-absolute join-sixth-purple-circle"></div>
                                <div className="rounded-circle position-absolute join-seventh-blue-circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* key benefits */}
            <section className="join-key-benefits-bg py-5">
                <div className="pe-md-5 pe-4 ps-md-0 ps-4 py-md-5 auto-container-lg">
                    <div>
                        <div className="row justify-content-end flex-md-row flex-column-reverse">
                            <div className="col-md-8 px-0 mt-md-5">
                                <div className="join-rotate-box">
                                    <div className="row justify-content-center">
                                        <div className="col-md-6">
                                            <div className="join-gradient-box  gradient-left">
                                                <KeyPurpleCircle
                                                    count={key_benefits?.divs[0]?.icon}
                                                    title={key_benefits?.divs[0]?.title}
                                                    className={'ms-auto'} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 ps-md-4 mt-md-0 mt-4">
                                            <div className="join-gradient-box gradient-right">
                                                <KeyPurpleCircle
                                                    count={key_benefits?.divs[1]?.icon}
                                                    title={key_benefits?.divs[1]?.title} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-md-5 mt-4 pt-md-2 ms-md-5">
                                            <div className="join-gradient-box gradient-left">
                                                <KeyPurpleCircle
                                                    count={key_benefits?.divs[2]?.icon}
                                                    title={key_benefits?.divs[2]?.title}
                                                    className={'ms-auto'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 px-0">
                                <div className="bd-responsive-section ps-md-0 ps-4">
                                    <div className="main-row resp-section-advant pt-5">
                                        <div className="bd-outer pr-15">
                                            <div className="bd-advan-border"></div>
                                        </div>
                                        <div>
                                            <h2 className="text-dark-blue fw-bold">{key_benefits?.title}</h2>
                                            <p className="text-dark-blue" style={{ fontSize: '15px' }}
                                                dangerouslySetInnerHTML={{ __html: key_benefits?.description }}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Advantages */}
            <section className="pt-5">
                <div className="auto-container-lg" >
                    <div>
                        <div className="row">
                            <div className="col-md-4 mb-4 pb-2 px-md-2 px-0">
                                <div className="bd-responsive-section">
                                    <div className="main-row resp-section-advant pt-120">
                                        <div className="bd-outer pr-15">
                                            <div className="bd-advan-border"></div>
                                        </div>
                                        <div>
                                            <h2 style={{ color: "#444444", fontWeight: "bold" }}>
                                                {propreneur_advantage?.title}</h2>
                                            <p className="text-dark-blue fs-6">
                                                {propreneur_advantage?.subtitle}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                propreneur_advantage?.divs?.map((advantage, index) => {
                                    return <AdvantageCard key={advantage.id}
                                        advantage={advantage}
                                        advantageDesigns={advantageDesigns[index]} />
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>

            {/* divercity */}
            <section className="diversity-bg mt-5" >
                <div className="row justify-content-between align-items-center mx-0 h-100">
                    <div className="col-md-4 py-5 mt-5 h-100">
                        {
                            diversity_of_propreneurs?.divs?.
                                slice(0, Math.ceil(diversity_of_propreneurs?.divs?.length / 2))
                                .map((diversityData) => {
                                    return <DivercityCard key={diversityData?.id}
                                        position={"left"} diversityData={diversityData} />
                                })
                        }
                    </div>

                    <div className="col-md-4 px-0 middle-design-lines">
                        <div className="middle-design pt-4">
                            <div className="d-flex justify-content-center ps-5 ">
                                <div className="bd-outer pr-15 ps-4 ms-3">
                                    <div className="bd-advan-border border-white"></div>
                                </div>
                                <div className="text-start text-capitalize">
                                    <h2 style={{ color: "#111", fontWeight: "bold" }}>{diversity_of_propreneurs?.title}</h2>
                                </div>
                            </div>
                            <div className="mt-4 pb-4">
                                <img src={diversity_of_propreneurs?.url} alt="" className="" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 py-5 mt-5 h-100">
                        {
                            diversity_of_propreneurs?.divs?.
                                slice(Math.ceil(diversity_of_propreneurs?.divs?.length / 2))
                                .map((diversityData) => {
                                    return <DivercityCard key={diversityData?.id}
                                        position={"right"} diversityData={diversityData} />
                                })
                        }
                    </div>
                </div>
            </section>

            {/* process image */}
            <section className="">
                <div className="">
                    <img className="w100 " src={onBoardImg} alt="" />
                </div>
            </section>

            {/* Frequently Asked Questions */}
            <section className="main-faq-section pt-80">
                <div className="auto-container-lg">
                    <div className="row align-items-center">
                        <div className="col-md-5 mt-md-0 mt-5">
                            <div className="img-section ">
                                <img className="w100" src={faq?.url} alt="" />
                            </div>
                        </div>
                        <div className="question-section col-md-7 mt-md-0 mt-5" >
                            <div className="pb-40">
                                <div className="main-row">
                                    <div className="pt-25 pr-15 pl-10 d-md-block d-none" >
                                        <div className="bd-faq-border"></div>
                                    </div>
                                    <div>
                                        <h2 style={{ fontWeight: "bold", fontSize: "36px" }}>
                                            {faq?.title}</h2>
                                    </div>
                                </div>

                            </div>

                            <div id="accordion">
                                {
                                    faq?.divs?.map((accordian, index) => {
                                        return <AccordianItem
                                            key={index}
                                            accordianId={index + 1}
                                            setActiveAccordian={setActiveAccordian}
                                            activeAccordian={activeAccordian}
                                            accordian={accordian} />
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <AgentJoinForm />

        </div>
    );
};

// Reusable components

const LeadMagnetLeft = ({ magnetData }) => {
    return (
        <div className="col-md-8">
            <div className="lead-magnet-card rounded-pill">
                <div className="magnet-left-card d-md-flex h-100 rounded-pill overflow-hidden">
                    <div className="magnet-img">
                        <img src={magnetData?.url} alt="" className="w-100 h-100" />
                    </div>
                    <div className="magnet-content px-md-5 px-4 py-md-0 py-4" style={{ flex: 1 }}>
                        <div className="pe-md-5"
                            dangerouslySetInnerHTML={{ __html: magnetData?.content }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
const LeadMagnetRight = ({ magnetData }) => {
    return (
        <div className="col-md-8 mt-5 pt-3 ms-auto">
            <div className="lead-magnet-card rounded-pill">
                <div className="magnet-right-card d-md-flex h-100 rounded-pill overflow-hidden">
                    <div className="magnet-content px-md-5 px-4 py-md-0 py-4" style={{ flex: 1 }}>
                        <div className=" text-end"
                            dangerouslySetInnerHTML={{ __html: magnetData?.content }}
                        />
                    </div>
                    <div className="magnet-img">
                        <img src={magnetData?.url} alt="" className="w-100 h-100" />
                    </div>
                </div>
            </div>
        </div>
    )
}

const KeyPurpleCircle = ({ count, title, className }) => {
    return (
        <div className={`key-purple-box ${className}`}>
            <div className="text-center px-4">
                <h3>{count}</h3>
                <p className="text-white mb-0 px-md-3">{title}</p>
            </div>
        </div>
    )
}

const AdvantageCard = ({ advantage, advantageDesigns }) => {
    return (
        <div className="col-md-4 px-md-3 px-0 mb-4 pb-2">
            <div className="bd-service advantage-card">
                <div >
                    <div className="textCenter mb-4">
                        <div className="advantage-icon" style={{ borderColor: advantageDesigns.color }}>
                            <i className={`bi bi-${advantageDesigns.icon} fs-1`} style={{ color: advantageDesigns.color }}></i>
                        </div>
                    </div>

                    <h2>
                        {advantage.title}
                        <div style={{ backgroundColor: advantageDesigns.color }}></div>
                    </h2>
                    <p className="bd-advantage-section ps-md-3"
                        dangerouslySetInnerHTML={{ __html: advantage.content }}
                    />
                </div>
            </div>
        </div>
    )
}

const DivercityCard = ({ position, diversityData }) => {
    return (
        <div className={`diversity-card diversity-card-${position} mb-5 ${position == 'left' ? 'me-5' : 'ms-5'}`}>
            <h3 className={`m${position == 'left' ? 'e' : 's'}-3`}>{diversityData?.title}</h3>
            <p
                dangerouslySetInnerHTML={{ __html: diversityData.content }}
            />
        </div>
    )
}

const AccordianItem = ({ accordian, setActiveAccordian, activeAccordian, accordianId }) => {
    const isActive = (activeAccordian == accordianId);

    function playAccordian() {
        setActiveAccordian(isActive ? '' : (accordianId))
    }

    return (
        <div className="card faq-card" key={accordian.id}>
            <div className="d-flex align-items-center">
                <div>
                    <button className="accordian-yellow-btn"
                        onClick={playAccordian}>
                        {isActive ? '-' : '+'}
                    </button>
                </div>
                <div className="accordion-title ms-3 fw-600"
                    onClick={playAccordian}
                >
                    {accordian.title}
                </div>
            </div>
            {
                isActive &&
                <div className="acc-desc ms-4 ps-md-4 ps-0 mt-4 mb-2"
                    dangerouslySetInnerHTML={{ __html: accordian.content }}
                />
            }
        </div>
    )
}

export default JoinPropreneurNew;
