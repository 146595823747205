export const customToastOptions = {
    style: {
        border: '1px transparent #ccc',
        padding: '27px 35px',
        backgroundColor: '#191d38',
        color: '#f5c954',
        borderRadius: '10px',
        fontSize: '1.15rem',
        whiteSpace: 'nowrap',
        // minWidth: '400px',
        fontWeight: '300'
    },
};