import React from 'react'

const CityAreaCard = ({ city }) => {
    let north;
    let east;

    if (city.coordinates) {
        north = city?.coordinates.split(',')[0];
        east = city?.coordinates.split(',')[1];
    } else {
        north = city?.lng;
        east = city?.lat;
    }

    return (
        <div className="tour-card">
            <img src={city.image} alt="" />

            <div className="content">
                <div>
                    <h5 className='text-yellow text-center'>{city.locality_name}</h5>

                    <div className={`text-white card-temperature mx-auto font-saira`}>
                        <span className='font-saira'>{north}° N</span>
                        <span className='font-saira'> {east}° E </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CityAreaCard