import React from 'react'
import Modal from 'react-bootstrap/Modal';

const MorePopup = ({ show, setShow }) => {

    const handleClose = () => setShow(false);

    return (
        <>
            <Modal show={show} onHide={handleClose} size='lg' centered animation={false}>
                <Modal.Header closeButton className='d-none'>
                    <Modal.Title className='fs-5' style={{ color: '#6d684b' }}>Residential</Modal.Title>
                    <Modal.Title className='ms-5 ps-3 fs-5' style={{ color: '#3f4847' }}>Commercial Property</Modal.Title>
                </Modal.Header>
                <div className='bg-yellow px-4 py-2 d-flex align-items-center justify-content-between'>
                    <div className='d-md-flex'>
                        <Modal.Title className='fs-5' style={{ color: '#6d684b' }}>Residential</Modal.Title>
                        <Modal.Title className='ms-md-5 ps-md-3 fs-5' style={{ color: '#3f4847' }}>Commercial Property</Modal.Title>
                    </div>
                    <div>
                        <i className='bi bi-x fs-1 lh-sm text-dark-blue' 
                            style={{cursor: 'pointer'}}
                        onClick={handleClose}></i>
                    </div>
                </div>
                <Modal.Body>
                    <div className="row">
                        <CategoryCard title="Industrial Plot" icon="globe" />
                        <CategoryCard title="Kiosk" icon="shop" />
                        <CategoryCard title="Agriculture Land" icon="image" />
                        <CategoryCard title="Shop" icon="shop" />
                        <CategoryCard title="Virtual Space Office" icon="phone" />
                        <CategoryCard title="Bank Space" icon="bank" />
                        <CategoryCard title="Unfurnished Office" icon="house" />
                        <CategoryCard title="Anchore Store" icon="house-up-fill" />
                        <CategoryCard title="Bar And Club" icon="person-workspace" />
                        <CategoryCard title="Fully Furnished Office" icon="buildings" />
                        <CategoryCard title="IT/Ites Land" icon="pin-map-fill" />
                        <CategoryCard title="Banquet Hall" icon="bank2" />
                        <CategoryCard title="Furnished Office" icon="building" />
                        <CategoryCard title="Land For Group Housing" icon="houses-fill" />
                        <CategoryCard title="Space For Showroom" icon="building-fill" />
                        <CategoryCard title="ATM" icon="credit-card" />
                        <CategoryCard title="Food Court" icon="building-fill-gear" />
                        <CategoryCard title="Commercial Land" icon="signpost-2" />
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

const CategoryCard = ({ title, icon }) => {
    return (
        // border-end border-bottom
        <div className="col-md-4 MoreCaregory">
            <div className='d-flex align-items-center p-2 '>
                <i className={`bi bi-${icon} fs-2`} style={{ color: '#686868' }}></i>
                <div className='ms-3' style={{ color: '#717d8e' }}>{title}</div>
            </div>
        </div>
    )
}

export default MorePopup