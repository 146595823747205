// import React, { useState } from "react";
// import axios from "axios";

// const NearbyCities = () => {
//     const [city, setCity] = useState("");
//     const [nearbyCities, setNearbyCities] = useState([]);

//     const RapidAPI_KEY = "e3ce8a096bmshbfd4a807a038f55p11ba35jsn050d1013ebb7";

//     const getNearbyCities = async () => {
//         try {
//             // Fetch the city data including its coordinates
//             const response = await axios.get(
//                 `https://wft-geo-db.p.rapidapi.com/v1/geo/cities`,
//                 {
//                     headers: {
//                         "X-RapidAPI-Key": RapidAPI_KEY,
//                         "X-RapidAPI-Host": "wft-geo-db.p.rapidapi.com",
//                     },
//                     params: {
//                         namePrefix: city,
//                         limit: 1,
//                     },
//                 }
//             );

//             // console.log(response.data.data[0])

//             setTimeout(async () => {
//                 if (response.data.data.length > 0) {
//                     //     const { latitude, longitude } = response.data.data[0];
//                     const latitude = 22.3732; // Replace with your correct latitude
//                     const longitude = -73.2000;
//                     // 54362
//                     // Fetch nearby cities using the coordinates
//                     const nearbyResponse = await axios.get(
//                         // `https://wft-geo-db.p.rapidapi.com/v1/geo/locations/${latitude}${longitude}/nearbyCities`,
//                         `https://wft-geo-db.p.rapidapi.com/v1/geo/cities/${response.data.data[0]?.id}/nearbyCities`,
//                         {
//                             headers: {
//                                 "X-RapidAPI-Key": RapidAPI_KEY,
//                                 "X-RapidAPI-Host": "wft-geo-db.p.rapidapi.com",
//                             },

//                             params: {
//                                 radius: 100, // Radius in kilometers
//                                 limit: 10
//                             },
//                         }
//                     );
//                     // console.log("cities : ", nearbyResponse.data)
//                     setNearbyCities(nearbyResponse.data.data);
//                 }
//             }, 2000)

//         } catch (error) {
//             console.error("Error fetching nearby cities:", error);
//         }
//     };

//     return (
//         <div className="pt-90 mt-4 mb-5 px-5">

//             <h3 className="mb-3">Find Nearby Cities</h3>
//             <input
//                 type="text"
//                 value={city}
//                 onChange={(e) => setCity(e.target.value)}
//                 placeholder="Enter city name"
//             />
//             <button disabled={city.length < 4} onClick={getNearbyCities}>Search</button>

//             <ol start={1} className="mt-4">
//                 {nearbyCities?.map((nearbyCity) => (
//                     <li key={nearbyCity.id} className="mb-2">
//                         {nearbyCity.name}, {nearbyCity.regionCode}
//                     </li>
//                 ))}
//             </ol>
//         </div>
//     );
// };

// export default NearbyCities;

import React from 'react';

const NearbyCities = () => {
    const phoneNumber = '919313911094'; // Replace with the recipient's phone number
    const message = 'Hello! This is a message from my React app.';
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    const handleClick = () => {
        window.open(whatsappUrl, '_blank');
    };

    return (

        <div className='mt-200 mb-5'>
            <h1>Send a Message via WhatsApp</h1>
            <button onClick={handleClick}>
                Send Message on WhatsApp
            </button>
        </div>
    );
};

export default NearbyCities;


