import { makeStyles } from "@material-ui/core/styles";
import backgrounImage from "../../assets/HeroBanner.jpg";
import searchIcon from "../../assets/Search.png";

const useStyles = makeStyles({
  landingPageBgImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${backgrounImage})`,
    width: "100%",
    height: "100vh",
    opacity: "1",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },



  centerBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    color: "#FDFDFD",
    font: "normal normal bold 72px Avenir Next",
    letterSpacing: "1.73px",
    textAlign: "center",
    fontSize: "40px",
    textOverflow: "ellipsis",
  },

  txt : {
    '@media only screen and (min-width: 320px) and (max-width: 480px)' : {
      fontSize: "28px"

    },
  },

  searchBox: {
    width: "600px",
    borderRadius: " 57px",

    '@media only screen and (min-width: 320px) and (max-width: 480px)' : {
      width:"65%"

    },

    '@media only screen and (min-width: 481px) and (max-width: 768px)' : {
      width:"80%"

    },

    '@media only screen and (min-width: 769px) and (max-width: 1024px)' : {
      width:"600px"

    },
  },

  searchIcon:{
    backgroundColor: "#122844",
    borderRadius: " 0px 50px 50px 50px",
    width: "50px",
    height: "50px",
    marginRight: "-15px",
    backgroundImage: `url(${searchIcon})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "25px",
    backgroundPosition: "center",


  },

  buttonContainer: {
    display: "flex",
    justifyContent: "left",
    width: "100%",
    marginTop: "18px",
    marginBottom: "7px",
  },
  buttonMargin: {
    marginLeft: "8px",
  },
  buybutton: {
    borderRadius: "40px",
    paddingTop: "7px",
    paddingBottom: "7px",
    paddingLeft: "27px",
    paddingRight: "27px",
    border: "none",
    backgroundColor: "#F2BE1A",

    '@media only screen and (min-width: 320px) and (max-width: 480px)' : {
      position:"relative",
      left:"110px",

    },

    '@media only screen and (min-width: 481px) and (max-width: 768px)' : {
      position:"relative",
      left:"150px",

    },

  },
  position:"relative",
      left:"110px",

  placeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "100%",
    color: "#F2BE1A",
    font: "normal normal medium 22px/35px Saira SemiCondensed",
    opacity: "1",
    paddingTop: "10px",


    '@media only screen and (min-width: 320px) and (max-width: 480px)' : {
      width: "450px",
      gap:'5px',
      justifyContent: "none",
      flexDirection: "column",
      flexWrap: "wrap",
      visibility: "hidden",


    },
  },

  places: {
    opacity: "1",
    fontSize: "13px !important",
  },

  dashPlaces: {
    opacity: "1",
    color: "#F0F0F0",
    height: "28px",
    borderRadius: "8px",
    marginTop: "0px",
    fontSize: "16px",
    '@media only screen and (min-width: 320px) and (max-width: 480px)':{
       display:"none"
    }
  },

  Sticky:{
    position:"absolute",
    right:"0px",
    top:"390px",
    width:"80px",

    '@media only screen and (min-width: 320px) and (max-width: 480px)' : {
      position: "absolute",
      top:"530px",
      width:"50px"
    },

    '@media only screen and (min-width: 481px) and (max-width: 768px)' : {
      position: "absolute",
      top:"530px",
      width:"60px"
    },

    '@media only screen and (min-width: 769px) and (max-width: 1024px)' : {
      position: "absolute",
      top:"530px",
      width:"70px",

    },

  }
});

export default useStyles;
