import React, { useEffect, useState } from 'react'

import { MapContainer, TileLayer, Marker, Popup, LayersControl } from 'react-leaflet';
import * as L from "leaflet";
import icon1 from "../../../assets/footer/pointer1.png"

import 'leaflet/dist/leaflet.css';

const customMarkerIcon = L.icon({
    iconUrl: icon1,
    iconSize: [20, 30],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});

const Map = ({ propertyData }) => {

    const [position, setPosition] = useState([])
    const [location, setLocation] = useState([])

    useEffect(() => {
        console.log(propertyData)
        if (propertyData?.id) {
            const latitude = Number(propertyData?.latitude) ? Number(propertyData?.latitude) : 23.3445
            const longitude = Number(propertyData?.longitude) ? Number(propertyData?.longitude) : 80.9628

            setPosition([latitude, longitude])

            setLocation([{
                name: propertyData?.property_name,
                latitude: latitude,
                longitude: longitude,
                address: propertyData?.address,
                state: propertyData?.locality_name + ', ' + propertyData?.city_name,
                image: propertyData?.image?.find(img => img?.upload_type === 'image')?.upload_name
            }])
        }
    }, [propertyData])

    return (
        <>
            <div className='w-100 propery_show_map' style={{ height: '500px', borderRadius: '14px', overflow: 'hidden' }}>
                {
                    position?.length > 0 &&
                    <MapContainer
                        center={position}
                        zoom={18}
                        style={{ height: '100%', width: '100%' }}
                        zoomControl={true}
                        scrollWheelZoom={false}
                    >

                        <LayersControl position="topright">
                            <LayersControl.BaseLayer checked name="Map View">
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    maxZoom={20}
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer name="Satellite View">
                                <TileLayer
                                    url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                                    subdomains={['mt1', 'mt2', 'mt3']}
                                    maxZoom={20}
                                    attribution='&copy; <a href="https://opentopomap.org/copyright">OpenTopoMap</a> contributors'
                                />
                            </LayersControl.BaseLayer>
                        </LayersControl>

                        {location?.map((location, index) => (
                            <Marker key={index}
                                position={[location.latitude, location.longitude]}
                                icon={customMarkerIcon}
                            >
                                <Popup
                                    autoPan={false}
                                    closeButton={false}
                                    offset={[0, 0]}
                                >
                                    <div className="d-flex">
                                        <div>
                                            <img src={location?.image}
                                                style={{ width: '100px', height: '100px', borderRadius: '10px', objectFit: 'cover' }} alt="" />
                                        </div>
                                        <div className='ms-3'>
                                            <h5 className='font-saira text-dark-blue'>{location.name}</h5>
                                            <p className="my-0 font-saira fw-bold ">
                                                {location?.state}
                                            </p>
                                            <p className='my-2 font-saira'>{location.address}</p>
                                        </div>
                                    </div>
                                    {/* <div>
                                        <p>Location {location.state}</p>
                                    </div> */}
                                </Popup>
                            </Marker>
                        ))}
                    </MapContainer>
                }
            </div>
        </>
    )
}

export default Map