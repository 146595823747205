import React, { useState } from 'react'
import toast from 'react-hot-toast';
import secureLocalStorage from 'react-secure-storage';
import { axiosbaseurl } from '../../../config';

const CommentForm = ({ post_id, type, setAllComments }) => {

    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [posted, setPosted] = useState(false);

    const credentials = secureLocalStorage.getItem("credentials")

    const handlePostComment = async (event) => {
        event.preventDefault();

        try {
            if (credentials) {
                setLoading(true)
                const formData = new FormData()

                formData.append("message", message)
                formData.append("post_id", post_id)
                formData.append("type", type)
                formData.append("user_id", credentials?.user_id)

                const response = await axiosbaseurl.post("/comments", formData)

                if (response.data.success) {
                    setAllComments(response.data.other?.reverse())
                    setMessage("")
                    setPosted(true)

                    setTimeout(() => setPosted(false), 10000)
                }
            } else {
                toast.error("Please login to post comment.")
            }

        } catch (error) {

        }
        finally {
            setLoading(false)
        }
    }

    return (
        <div>
            <div>
                {
                    posted &&
                    <div className='alert alert-success border-0 d-flex justify-content-between align-items-center py-2 fw-500' >
                        <div>Comment Posted Successfully</div>
                        <div onClick={() => setPosted(false)} style={{ cursor: 'pointer' }}>
                            <i className='bi bi-x fs-3'></i>
                        </div>
                    </div>
                }
            </div>
            <form onSubmit={handlePostComment}>
                <label htmlFor="" className='text-dark-blue mb-2'>Message</label>
                <div className='comment-box position-relative'>
                    <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder='Write your comment here'
                        className='comment-field' required>

                    </textarea>
                </div>

                <div className='my-4'>
                    <button type='submit' className='comment-btn rounded-pill' disabled={loading}>
                        <i className={`bi bi-${loading ? 'opencollective rotate-icon' : 'send'} me-3 text-yellow`}></i>
                        Post Comment
                    </button>
                </div>
            </form>
        </div>
    )
}

export default CommentForm