import React, { useContext, useEffect } from 'react'

import { useLocation, useNavigate } from "react-router-dom"
import propuserlogo from '../../assets/Propusers_logo-White.png';
import config from '../../config';
import { AppGlobalContext } from '../../GlobalContext';

const PassChange = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const { boardingData } = useContext(AppGlobalContext);

  useEffect(() => {
    if (!location?.state?.data) {
      navigate(`${config.appUrl}sign-in`)
    }
  }, [])

  return (
    <div>


      <div
        className='bg-image py-4 row align-items-center mx-0'
        style={{
          marginTop: "90px",
          minHeight: '110vh',
          backgroundImage: `linear-gradient(to left, transparent 20%, #374053), url(${boardingData?.login?.image})`
        }}
      >
        <div className="col-lg-5 col-md-7 ms-md-5 px-md-5">

          <div className='form-container '>
            <div className='top-form-section'>
              <img src={propuserlogo} alt=""></img>
              <h4 className='my-4 pt-3 ' >{boardingData?.password?.title}</h4>
            </div>

            <div className='top-form-section mb-5'>
              <div>
                <i className='bi bi-patch-check-fill display-2' style={{ color: '#39b54a' }}></i>
              </div>
              <div dangerouslySetInnerHTML={{ __html: boardingData?.password?.subtitle }} />
              
              <p className='text-white mb-1'><strong>{location?.state?.data}</strong></p>
            </div>

            <div className='pb-4'>
              <button className='btn-blue w-100' onClick={() => navigate(`${config.appUrl}login`)}>
                {
                  "Okay"
                }
              </button>
            </div>

          </div>
        </div>
      </div>

    </div>
  )
}

export default PassChange