import React from 'react'

const Photos = ({ currentShowCase }) => {
    return (
        <div className="row">
            {
                currentShowCase?.map(image => {
                    return (
                        <div className="col-md-4 mb-md-0 mb-4">
                            <div className="photo-card">
                                <img src={image?.upload_name} alt="" />
                                <div><p>{image?.tag}</p></div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Photos