import React, { useContext, useState } from 'react'
 ;
import propuserlogo from '../../assets/Propusers_logo-White.png';
import { useNavigate } from "react-router-dom"
import config, { axiosbaseurl } from '../../config';
import secureLocalStorage from 'react-secure-storage';
import toast, { Toaster } from 'react-hot-toast';
import { customToastOptions } from '../../Styles/popup_style';
import { AppGlobalContext } from '../../GlobalContext';

const Verifypage = () => {

  const [passwords, setPasswords] = useState(null)
  const [loading, setLoading] = useState(false)
  const [show_pwd, setShowPwd] = useState(false)
  const [show_pwd2, setShowPwd2] = useState(false)
  const { boardingData } = useContext(AppGlobalContext);

  const handleLoginUser = async (e) => {
    e.preventDefault()

    if (passwords?.confirm_password == passwords?.password) {
      setLoading(true)
      const user_info = secureLocalStorage.getItem("temp_data")
      const formData = {
        ...passwords,
        user_id: user_info.user_id
      }

      try {
        const response = await axiosbaseurl.post('/resetPassword', formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          }
        })

        if (response.data.success == true) {
          toast.success('Password Reset Succesfully!')

          setTimeout(() => {
            secureLocalStorage.removeItem("temp_data")
            navigate(`${config.appUrl}PassChange`, { state: { data: response.data.email } })
          }, 2000);
        }

        setLoading(false)

      } catch (err) {
        console.log(err)
        setLoading(false)
      }
    } else {
      toast.error('Confirm password not matched !!');
    }
  }

  const navigate = useNavigate();

  function setFormData(e) {
    setPasswords(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  return (
    <div>
      <Toaster position="top-right" reverseOrder={false} toastOptions={customToastOptions} />
       
      <div>
        <div
          className='bg-image py-4 row align-items-center mx-0'
          style={{
            marginTop: "90px",
            minHeight: '100vh',
            backgroundImage: `linear-gradient(to left, transparent 20%, #374053), url(${boardingData?.login?.image})`
          }}
        >
          <div className="col-lg-5 col-md-7 ms-md-5 px-md-5">

            <div className='form-container '>
              <div className='top-form-section'>
                <img src={propuserlogo} alt=""></img>
                <h4 className='pt-5 pb-4'>Reset Password</h4>
              </div>


              <form onSubmit={handleLoginUser}>
                <div className="row">


                  <div className="col-12">

                    <div className="form-floating mb-2">
                      <input type={`${show_pwd ? 'text' : 'password'}`} name='password'
                        onChange={setFormData}
                        value={passwords?.password}
                        className={'form-control form-field'}
                        required
                        placeholder="New Password"></input>

                      <label htmlFor="name">Enter New Password</label>
                      <i className={`bi bi-eye${show_pwd ? '-slash' : ''}-fill pass-eye`} onClick={() => setShowPwd(!show_pwd)}></i>
                    </div>
                  </div>

                  <div className="col-12 mt-4">

                    <div className="form-floating mb-2">
                      <input type={`${show_pwd2 ? 'text' : 'password'}`} name='confirm_password'
                        onChange={setFormData}
                        value={passwords?.confirm_password}
                        className={'form-control form-field'}
                        required
                        placeholder='Confirm Password'
                      ></input>

                      <label htmlFor="name">Re-Enter New Password</label>
                      <i className={`bi bi-eye${show_pwd2 ? '-slash' : ''}-fill pass-eye`} onClick={() => setShowPwd2(!show_pwd2)}></i>
                    </div>
                  </div>

                </div>

                <div className='mt-4 mb-5'>
                  <button className='btn-blue w-100' type='submit' >
                    {
                      loading ? "Re-setting password..." : "Submit"
                    }
                  </button>
                </div>

              </form>

            </div>
          </div>
        </div>
      </div>
       
    </div>
  )
}

export default Verifypage