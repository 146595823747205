import React, { useEffect, useState } from 'react';
import "../../assets/Css/News.css";
import { Link } from 'react-router-dom';
import moment from 'moment';
import config from '../../config';

const NewsCard = ({ news, translateY, handleSavedPost }) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    return (

        <div className="news-card" style={{
            backgroundImage: `linear-gradient(#11111170, #11111170), url("${news.url}")`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            transform: isMobile ? 'none' : `translateY(${translateY}px)`
        }}>

            {
                news?.isSaved ?
                    <div className='p-3 text-end'>
                        <span className='badge bg-yellow text-dark '>Saved</span>
                    </div>
                    :
                    <div className='news-save d-flex align-items-center justify-content-end'
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                            e.stopPropagation()
                            handleSavedPost(news.id)
                        }}
                    >
                        Save/Read Later &nbsp;
                        <span className="save-span">
                            <i className="bi bi-arrow-down"></i>
                        </span>
                    </div>
            }

            <div className='property1'>
                {
                    news?.category &&
                    <button className="property1-btn">
                        <span>{news?.category}</span>
                    </button>
                }
                <div className='mt-3'>
                    <Link to={`${config.appUrl}news/${news.slug?.replaceAll('%', '')}`} className='hotprop-content1 text-decoration-none'>
                        {news.post_title}
                    </Link>
                </div>
                <div className="date2">
                    <span className='font-saira'>
                        {moment(news.created_at).format('MMMM D, YYYY')}
                        <span className="text-yellow px-2 fs-6">|</span>
                        <i className="bi bi-eye me-1"></i>  {news?.views}
                        <span className="text-yellow px-2 fs-6">|</span>
                        <i className="bi bi-wechat me-1"></i>  {news?.comments}
                    </span>
                </div>
                <div>
                    <div className='read-btn'>
                        <Link to={`${config.appUrl}news/${news.slug?.replaceAll('%', '')}`} className='text-decoration-none'>
                            <div className='text-white d-flex align-items-center'>
                                READ MORE &nbsp; &nbsp;
                                <span className="save-span">
                                    <i className="bi bi-arrow-right"></i>
                                </span>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default NewsCard;
