import React, { useEffect, useState } from 'react'

import { MapContainer, TileLayer, Marker, Popup, LayersControl } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import icon1 from "../../../assets/footer/pointer_red.png"
import axios from 'axios';

const customMarkerIcon = L.icon({
    iconUrl: icon1,
    iconSize: [30, 40],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});

const countryStyle = {
    color: 'black',
    opacity: 1,
    height: '100%',
    width: '100%'
};

// Function to create a round text marker
const createRoundTextMarker = (icon) => {
    return new L.DivIcon({
        className: 'custom-icon',
        html: `<div class='font-saira fw-600 d-flex justify-content-center align-items-center' 
        style="background-color: #F5C954; color: #122844; outline: 4px solid #fff; border-radius: 50%; text-align: center; width: 40px; height: 40px; font-size: 22px; ">
            <i class="bi bi-${icon}">
        </div>`,
        iconSize: [60, 60],
        iconAnchor: [60 / 2, 60 / 2],
        popupAnchor: [0, -60 / 2]
    });
};

// Custom icons for cities, areas, and localities
const placeIcon = (text) => createRoundTextMarker(text); // Larger size


// map component 
const PropertyDetailMap = ({ latitude, longitude, address }) => {

    const [position, setPosition] = useState([])
    const [location, setLocation] = useState([])
    const [placeList, setPlaceList] = useState([])
    const [places, setPlaces] = useState({})

    const [place_card_items, setCardItems] = useState([
        {
            label: "City Connection",
            fieldname: "connections",
            icon: "building-add",
            count: 0
        },
        {
            label: "Shopping Centre",
            fieldname: "shopping_centers",
            icon: "shop",
            count: 0
        },
        {
            label: "Hospital",
            fieldname: "hospitals",
            icon: "plus-lg",
            count: 0
        },
        {
            label: "School",
            fieldname: "schools",
            icon: "buildings-fill",
            count: 0
        },
        {
            label: "Restaurants",
            fieldname: "restaurants",
            icon: "shop-window",
            count: 0
        }
    ])

    useEffect(() => {
        if (latitude && longitude) {
            setPosition([Number(latitude), Number(longitude) - 0.010])
            setLocation([{
                latitude: latitude,
                longitude: longitude,
                address
            }])
            fetchPlaces()
        }

    }, [latitude, longitude])

    const fetchPlaces = async () => {
        const query = `
          [out:json];
          (
            node["amenity"="restaurant"](around:4000, ${latitude}, ${longitude});
            node["amenity"="school"](around:4000, ${latitude}, ${longitude});
            node["amenity"="hospital"](around:4000, ${latitude}, ${longitude});
            node["shop"="supermarket"](around:4000, ${latitude}, ${longitude});
            // node["place"="city"](around:150000, ${latitude}, ${longitude});
            // way["highway"="primary"](around:5000, ${latitude}, ${longitude});
          );
          out body;
        `;

        const options = {
            method: 'POST',
            url: 'https://overpass-api.de/api/interpreter',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: `data=${encodeURIComponent(query)}`,
        };

        try {
            const response = await axios.request(options);
            // console.log('overpass API : ', response.data.elements); // Adjust according to the API response structure

            const allPlaces = Array.from(new Set(response.data.elements?.filter(item => item?.tags?.name)))

            const schools = allPlaces?.filter(item => item?.tags?.amenity === 'school');
            const restaurants = allPlaces?.filter(item => item?.tags?.amenity === 'restaurant');
            const hospitals = allPlaces?.filter(item => item?.tags?.amenity === 'hospital');
            const shopping_centers = allPlaces?.filter(item => item?.tags?.shop === 'supermarket');
            // console.log('city', allPlaces?.filter(item => item?.type === 'way')?.map(item => item?.tags?.name))

            // const uniqueHighway = []
            // allPlaces?.filter(item => item?.type === 'way')?.map(item => item?.tags?.name).forEach((item, index) => {
            //     if (!uniqueHighway.includes(item))
            //         uniqueHighway.push(item)
            // })
            // console.log('unique ', uniqueHighway)
            setPlaces({
                schools: schools,
                restaurants: restaurants,
                shopping_centers: shopping_centers,
                hospitals: hospitals
            })

            // updating no. of places 
            const exist_items = [...place_card_items];
            exist_items[1].count = shopping_centers?.length;
            exist_items[2].count = hospitals?.length;
            exist_items[3].count = schools?.length;
            exist_items[4].count = restaurants?.length;
            setCardItems(exist_items)

            // initial place to show on map (school)
            const locationData = schools?.map((place) => ({
                latitude: place?.lat,
                longitude: place?.lon,
                name: place?.tags?.name,
                icon: 'buildings-fill',
                place_type: 'schools',
                distance: haversineDistance(latitude, longitude, place?.lat, place?.lon)
            }))
            setPlaceList(locationData)


        } catch (error) {
            console.error('Error fetching places:', error);
        }
    };

    // calculate distance in km
    const haversineDistance = (lat1, lon1, lat2, lon2) => {
        const toRad = (value) => (value * Math.PI) / 180;

        const R = 6371; // Radius of the Earth in km
        const dLat = toRad(lat2 - lat1);
        const dLon = toRad(lon2 - lon1);

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c; // Distance in km
    };


    // to show on map
    const generateLocations = (place_type, iconName) => {
        const locationData = places[place_type]?.map((place) => ({
            latitude: place?.lat,
            longitude: place?.lon,
            name: place?.tags?.name,
            icon: iconName,
            place_type: place_type,
            distance: haversineDistance(latitude, longitude, place?.lat, place?.lon)
        }))
        // console.log('locations ', locationData)
        setPlaceList(locationData)
    }

    return (
        <>
            <div className='position-relative'>
                <div className='bg-light '>
                    <div className=' nearby-places'>
                        {
                            position.length > 0 &&
                            <MapContainer
                                center={position}
                                zoom={14}
                                zoomControl={true}
                                scrollWheelZoom={true}
                                doubleClickZoom={true}
                                touchZoom={true}
                                boxZoom={false}
                                style={countryStyle}
                            >
                                {/* <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                /> */}

                                <LayersControl position="topright">
                                    <LayersControl.BaseLayer checked name="Map View">
                                        <TileLayer
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            maxZoom={20}
                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        />
                                    </LayersControl.BaseLayer>
                                    <LayersControl.BaseLayer name="Satellite View">
                                        <TileLayer
                                            url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                                            subdomains={['mt1', 'mt2', 'mt3']}
                                            maxZoom={20}
                                            attribution='&copy; <a href="https://opentopomap.org/copyright">OpenTopoMap</a> contributors'
                                        />
                                    </LayersControl.BaseLayer>
                                </LayersControl>

                                {location?.map((location, index) => (
                                    <Marker key={index}
                                        position={[location.latitude, location.longitude]}
                                        icon={customMarkerIcon}
                                    >
                                        <Popup
                                            autoPan={false}
                                            closeButton={false}
                                            offset={[0, 5]}
                                        >
                                            <div>{location.address}</div>
                                            <span>Property Location</span>
                                        </Popup>
                                    </Marker>
                                ))}

                                {
                                    placeList?.length &&
                                    placeList.map((place, index) => {
                                        return (
                                            <Marker
                                                key={index}
                                                position={[place.latitude, place.longitude]}
                                                icon={placeIcon(place.icon)} // Apply the custom icon for localities with count
                                            >
                                                <Popup
                                                    autoPan={false}
                                                    closeButton={false}
                                                    offset={[0, 5]}
                                                >
                                                    <div>{place.name}</div>
                                                    <span>{place.distance.toFixed(1)}Km from Location</span>
                                                </Popup>
                                            </Marker>
                                        )
                                    })
                                }

                            </MapContainer>
                        }
                    </div>
                </div>
                <div className='property-location-box'>

                    {
                        place_card_items.map((card_item) => {
                            return (
                                <div key={card_item.fieldname} className='property-location-white-box'
                                    onClick={() => {
                                        if (card_item.count)
                                            generateLocations(card_item.fieldname, card_item.icon)
                                    }}>
                                    <div><i className={`bi bi-${card_item.icon} display-6`}></i></div>
                                    <div className='ms-3 font-saira fw-500 paragraph'>{card_item.label}</div>
                                    <div style={{ flex: 1 }}>
                                        <div className={`${placeList.length && card_item.fieldname === placeList[0].place_type && 'bg-yellow'}`}>{card_item.count}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        </>
    )
}

export default PropertyDetailMap

