import React, { useContext, useEffect, useRef, useState } from 'react'

import propuserlogo from '../../assets/Propusers_logo-White.png';
import { useNavigate } from "react-router-dom"
import config, { axiosbaseurl } from '../../config';
import secureLocalStorage from 'react-secure-storage';
import { customToastOptions } from '../../Styles/popup_style';
import toast, { Toaster } from 'react-hot-toast';
import CustomLoader from '../CustomLoader';
import { AppGlobalContext } from '../../GlobalContext';

const CreateAccountOtp = () => {

    const [isOtpVerified, setOtpVerified] = useState(false)
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    const user_info = secureLocalStorage.getItem("temp_data")
    const length = 6

    const initialOtp = user_info ? (String(user_info?.otp)?.split("")) : (Array(length).fill(''))
    const [otpValues, setOtpValues] = useState(initialOtp);
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

    useEffect(() => {
        setOtpValues(String(user_info?.otp)?.split(""))
    }, [user_info])

    useEffect(() => {
        if (!user_info) {
            navigate(`${config.appUrl}register`)
        }
        return () => {
            secureLocalStorage.removeItem("temp_data")
        }
    }, [])

    const [seconds, setSeconds] = useState(60);
    const [loading2, setLoading2] = useState(false);

    const { boardingData } = useContext(AppGlobalContext);


    // 60 sec functionality
    useEffect(() => {
        if (seconds === 0) {
            return; // Stop the countdown if seconds reach 0
        }

        const interval = setInterval(() => {
            setSeconds(prevSeconds => prevSeconds - 1);
        }, 1000);

        // Clear the interval when the component unmounts or when seconds reach 0
        return () => clearInterval(interval);
    }, [seconds]);

    // Resend Otp

    const handleReSendOtp = async () => {
        // e.preventDefault()
        const formData = {
            email: user_info?.email,
            type: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(user_info?.email) ? 1 : 2
        }

        try {
            setLoading2(true)
            const response = await axiosbaseurl.post('/sendOtp', formData, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                }
            })

            if (response.data.success == true) {
                const temp = {
                    otp: response.data.otp,
                    user_id: response.data.user,
                    email: user_info?.email
                }

                secureLocalStorage.setItem('temp_data', temp)

                toast.success('Otp Resent Successfully!')
                setSeconds(60)
            } else {
                toast.error('User Not Found!');
            }
            setLoading2(false)

        } catch (error) {
            console.log(error)
            toast.error('Something went wrong!');
            setLoading2(false)
        }
    }

    // ======================

    const handleCompareOtp = async (e) => {
        e.preventDefault()

        // 
        if (otpValues.join('') == user_info.otp) {
            setLoading(true)

            const formData = new FormData()
            formData.append('user_id', user_info?.user_id)
            axiosbaseurl.post('/verifyUser', formData)
                .then((res) => {
                    if (res.data.data == "user verified") {
                        toast.success('OTP Verified Successfully!')
                        setOtpVerified(true)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => setLoading(false))
        } else {
            toast.error('Invalid OTP!');
        }

    }

    const handleInputChange = (index, event) => {
        const { value } = event.target;
        const newValues = [...otpValues];
        newValues[index] = value;
        setOtpValues(newValues);

        // Move to the next input when a digit is entered
        if (value !== '' && index < length - 1) {
            inputRefs[index + 1].current.focus();
        }
    };

    const handleBackspace = (index, event) => {
        if (event.keyCode === 8 && index > 0 && otpValues[index] === '') {
            inputRefs[index - 1].current.focus();
        }
    };

    return (
        <div>
            {loading2 && <CustomLoader />}
            <Toaster position="top-right" reverseOrder={false} toastOptions={customToastOptions} />

            <div >
                <div
                    className='bg-image py-4 row align-items-center justify-content-end mx-0'
                    style={{
                        marginTop: "90px",
                        minHeight: '100vh',
                        backgroundImage: `linear-gradient(to right, transparent 20%, #374053), url(${boardingData?.signup?.image})`
                    }}
                >
                    <div className="col-lg-5 col-md-7 ms-md-5 px-md-5">
                        {
                            !isOtpVerified ?
                                <div className='form-container '>
                                    <div className='top-form-section mb-5'>
                                        <img src={propuserlogo} alt=""></img>
                                        <h4 className='pt-5 fw-bold'>Verify</h4>
                                        <div className='text-white'>
                                            <p className=' my-2'>Enter {length} Digit Verification code sent on</p>
                                            {/* <b>{user_info?.email}</b> */}
                                            <b>{user_info?.email}</b>
                                        </div>
                                    </div>


                                    <form onSubmit={handleCompareOtp}>
                                        <div className="row">

                                            <div className="col-12 mb-3">

                                                <div className='form-field'>
                                                    <label htmlFor="">Enter {length} digits OTP</label>
                                                    <div className="row mt-1">

                                                        {otpValues.map((value, index) => (
                                                            <div key={index} className="col px-1">
                                                                <input
                                                                    key={index}
                                                                    ref={inputRefs[index]}
                                                                    type="text"
                                                                    className='fs-6 form-field px-1 bg-white text-center text-dark py-2 shadow-sm'
                                                                    maxLength={1}
                                                                    value={value}
                                                                    onChange={(e) => handleInputChange(index, e)}
                                                                    onKeyDown={(e) => handleBackspace(index, e)}
                                                                    required
                                                                />
                                                            </div>
                                                        ))}

                                                    </div>
                                                </div>

                                                <div className='top-form-section d-flex justify-content-between align-items-start text-white mt-2'>
                                                    <p>Valid upto {seconds} Sec</p>
                                                    <button onClick={handleReSendOtp}
                                                        className='text-yellow bg-transparent py-0 border-0 fs-6 opacity-4'
                                                        disabled={seconds != 0}
                                                        type='button'>
                                                        Resend
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='pb-5'>
                                            <button className='btn-blue w-100' type='submit' >
                                                {
                                                    loading ? "Verifying Otp.." : "Verify"
                                                }
                                            </button>
                                        </div>

                                    </form>

                                </div>
                                :
                                <div className='form-container '>
                                    <div className='top-form-section'>
                                        <img src={propuserlogo} alt=""></img>
                                        <h4 className='my-4 pt-3 '>{boardingData?.account?.title}</h4>
                                    </div>

                                    <div className='top-form-section mb-5'>
                                        <div>
                                            <i className='bi bi-patch-check-fill display-2' style={{ color: '#39b54a' }}></i>
                                        </div>
                                        
                                        <div 
                                            className='text-white'
                                            dangerouslySetInnerHTML={{__html: boardingData?.account?.subtitle}}
                                        />

                                        {/* <h3 className='fs-2 fw-bold text-white'>Thank You</h3>
                                        <p className='text-white mb-1'> Your Account has been Created Successfully</p>
                                        <p className='text-white mb-1'>A Confirmation email has been sent to</p> */}
                                        <p className='text-white mb-1'><strong>{user_info?.email}</strong></p>
                                    </div>

                                    <div className='pb-4'>
                                        <button className='btn-blue w-100' onClick={() => navigate(`${config.appUrl}login`)}>
                                            {
                                                "Okay"
                                            }
                                        </button>
                                    </div>

                                </div>
                        }



                    </div>
                </div>
            </div>


        </div>
    )
}

export default CreateAccountOtp