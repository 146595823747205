import React, { useEffect, useState } from 'react'
import ViewComment from './ViewComment'
import CommentForm from './CommentForm'
import { axiosbaseurl } from '../../../config'

const CommentSection = ({ post_id, type }) => {

    const [allComments, setAllComments] = useState([])

    useEffect(() => {
        async function getComments() {
            try {
                const formData = new FormData()

                formData.append("post_id", post_id)
                formData.append("type", type)

                const response = await axiosbaseurl.post("/view_comments", formData)

                if (response.data.success) {
                    setAllComments(response.data.comments?.reverse())
                }
            } catch (error) {
                console.log(error)
            }
        }
        if (post_id)
            getComments()
    }, [post_id])

    return (
        <>
            {
                allComments.length > 0 &&
                <div className="comments-section mt-5">
                    <section className='ps-md-5 ps-4'>
                        <h3 className='font-saira fw-600 text-dark-blue fs-3'>Users Comments</h3>
                        <div className="yellow-line mb-4"></div>
                    </section>

                    <div className='ps-md-5 ps-4'>
                        {
                            allComments?.map(comment => {
                                return <ViewComment key={comment.id} commentData={comment} />
                            })
                        }
                    </div>
                </div>
            }
            <div className="comments-section mt-5">
                <div className='ps-md-5 ps-4 mt-2'>
                    <h3 className='font-saira fw-600 text-dark-blue fs-3'>Leave Your Comment</h3>
                    <div className="yellow-line mb-4"></div>
                </div>
                <div className='ps-md-5 ps-4 mt-2'>
                    <CommentForm post_id={post_id} type={type} setAllComments={setAllComments} />
                </div>
            </div>
        </>
    )
}

export default CommentSection