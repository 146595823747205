import React, { useState } from "react";
import MakeoverLogo from "../../assets/Makeover Logo.svg";
import featureicon1 from "../../assets/Key Features Icons/key_icon1.png";
import featureicon2 from "../../assets/Key Features Icons/key_icon2.png";
import featureicon4 from "../../assets/Key Features Icons/key_icon4.png";
import arrowRightImg from "../../assets/Key Features Icons/arrow-right.png";
import { Link } from "react-router-dom";
import config from "../../config";

function MakeOver({ makeOverData, keyFeatures = [] }) {
  const title = makeOverData?.title;
  const words = title?.split(" ") || [];
  const firstWord = words[0] || "";
  const lastWord = words[words.length - 1] || "";
  const updatedTitle = words.slice(1, -1).join(" ");
  const subtitle = makeOverData?.subtitle;
  const subtitleWords = subtitle?.split(" ") || [];
  const firstSubtitleWord = subtitleWords[0] || "";
  const lastSubtitleWord = subtitleWords[subtitleWords.length - 1] || "";
  const updatedSubtitle = subtitleWords.slice(1, -1).join(" ");

  const [isMorePopup, setIsMorePopup] = useState(false);
  const [isMorePopupData, setIsMorePopupData] = useState({});

  // features Icons
  const featuresIcons = [featureicon1, featureicon2, featureicon2, featureicon4]

  const readMorePopup = async (index, featureData) => {
    setIsMorePopupData({ ...featureData, img: featuresIcons[index] });
    setIsMorePopup(true);
  };

  return (
    <div className="makeover-box py-5 px-md-5 px-4">
      <div className="text-md-end">
        <div className="makeover-heading mb-md-0 ">
          <div>
            {firstWord} <span> {updatedTitle} </span> {lastWord}
          </div>
          <div>
            {firstSubtitleWord} <span> {updatedSubtitle} </span> {lastSubtitleWord}
          </div>
        </div>

        <div className="d-md-block d-flex align-items-center mt-5 pt-5 ">
          <Link to={`${config.appUrl}propuser-makeover`} className="pt-md-0 pt-5">
            <img src={MakeoverLogo} alt="" className="makeover-logo" />
            <img src={arrowRightImg} alt="" className="ms-4 arrow_right_img mt-md-0 mt-3" />
          </Link>
        </div>

        <div className="row justify-content-end makeover-keyfeatures mt-5">
          <div className="col-md-6 col-xl-4 col-sm-10">
            <div className="d-flex align-items-center my-2 translate-left">
              <div className="line"></div>
              <h5 className="mb-0">Key Features</h5>
            </div>
            <div className="position-relative">
              <div className="row translate-left">
                <div className="col-sm-6 my-3">
                  <div className="d-flex align-items-center text-start">
                    <div className="key-icon">
                      <img src={featuresIcons[0]} alt="" />
                    </div>
                    <div className="ms-3">
                      <h5 style={{ fontSize: "18px" }}>
                        {keyFeatures[0]?.title ?? ""}
                      </h5>
                      <div
                        className="text-white makeover-read-more"
                        onClick={() => readMorePopup(0, keyFeatures[0])}
                      >
                        Read more..
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 my-3">
                  <div className="d-flex align-items-center text-start">
                    <div className="key-icon">
                      <img src={featuresIcons[1]} alt="" />
                    </div>
                    <div className="ms-3">
                      <h5 style={{ fontSize: "18px" }}>{keyFeatures[1]?.title}</h5>
                      <div
                        className="text-white makeover-read-more"
                        onClick={() => readMorePopup(1, keyFeatures[1])}
                      >
                        Read more..
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justifyy-content-end">
                <div className="col-sm-6 my-3">
                  <div className="d-flex align-items-center text-start">
                    <div className="key-icon">
                      <img src={featuresIcons[2]} alt="" />
                    </div>
                    <div className="ms-3">
                      <h5 style={{ fontSize: "18px" }}>{keyFeatures[2]?.title}</h5>
                      <div
                        className="text-white makeover-read-more"
                        onClick={() => readMorePopup(2, keyFeatures[2])}
                      >
                        Read more..
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 my-3">
                  <div className="d-flex align-items-center text-start">
                    <div className="key-icon">
                      <img src={featuresIcons[3]} alt="" />
                    </div>
                    <div className="ms-3">
                      <h5 style={{ fontSize: "18px" }}>{keyFeatures[3]?.title}</h5>
                      <div
                        className="text-white makeover-read-more"
                        onClick={() => readMorePopup(3, keyFeatures[3])}
                      >
                        Read more..
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {isMorePopup && (
                <div className="readmore-popup shadow bg-white px-4 py-3">
                  <div className="d-flex "></div>
                  <div className="d-flex mb-3 justify-content-between">
                    <div className="d-flex align-item-center">
                      <div className="key-icon">
                        <img src={isMorePopupData?.img} alt="" />
                      </div>

                      <div>
                        <h4 className="m-0 ps-3">
                          {isMorePopupData?.title}
                        </h4>
                      </div>
                    </div>
                    <div
                      className="curser-pointer"
                      onClick={() => {
                        setIsMorePopup(false);
                        setIsMorePopupData({});
                      }}
                    >
                      <i className="bi bi-x text-dark-blue fs-4"></i>
                    </div>
                  </div>
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: isMorePopupData?.description }}  >
                      </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MakeOver;
