import React, { useState } from 'react'

const SortingSelect = ({ selectedSortItem, setSelectedSortItem, list_items, startSorting, showItems, setShowItems }) => {

    // const [showItems, setShowItems] = useState(false)

    return (
        <div>
            <div className='position-relative'>
                <div className='custom-designed-select sort-select-field' onClick={() => setShowItems(prev => !prev)}>
                    <div className='text-center px-3 font-saira'> Sort by {selectedSortItem.title} </div>
                    <button className="fs-6">
                        <i className='fa fa-chevron-down'></i>
                    </button>
                </div>
                {
                    showItems &&
                    <div className='sort-select-items'>
                        {
                            list_items.map(item => {
                                return (
                                    <div
                                        key={item.value}
                                        onClick={() => {
                                            if (item.sortData) {
                                                setSelectedSortItem(item)
                                                setShowItems(false)
                                                startSorting(item.sortData)
                                            }
                                        }}
                                        className={`d-flex justify-content-between p-3 align-items-center
                                     ${selectedSortItem.value == item.value && 'text-yellow'}`}>
                                        <div className='font-saira fw-500'>{item.title}</div>
                                        {
                                            selectedSortItem.value == item.value &&
                                            <div><i className='bi bi-check text-yellow fs-4' style={{ lineHeight: '0' }}></i></div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>

        </div>
    )
}

export default SortingSelect