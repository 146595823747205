import React, { useEffect, useState } from 'react'
import config, { axiosbaseurl } from '../../../config'
import { Link, useLocation, useParams } from 'react-router-dom'
import "./PropertyShowCase.css"
import Photos from './Photos'
import VirtualTour from './VirtualTour'
import Videos from './Videos'
import Map from './Map'
import FloorPlans from './FloorPlans'
import CustomLoader from '../../CustomLoader'

const PropertyShowCase = () => {

    const [showCase, setShowCase] = useState([])
    const [currentShowCase, setCurrentShowCase] = useState([])
    const [currentProperty, setCurrentProperty] = useState({})
    const [loading, setLoading] = useState(false)

    const locationData = useLocation()
    const { tab_name, property_type, property_id } = useParams()

    useEffect(() => {

        const controller = new AbortController();
        async function getPropExpData() {
            try {
                setLoading(true);
                const sendData = {
                    property_id,
                    user_id: 0
                }
                const { data } = await axiosbaseurl.post(`/propertiesData`, sendData);

                if (data.success == true) {
                    const curr_pro = data.data[0]
                    setCurrentProperty(curr_pro)
                    // Storing Slider Images
                    setShowCase(curr_pro?.image)
                    setLoading(false);
                }
            } catch (error) {
                console.log(error);
            }
        }

        getPropExpData();

        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        if (showCase) {
            switch (tab_name) {
                case "photos": setShowCaseData('image')
                    return
                case "videos": setShowCaseData('video')
                    return
                case "virtual-tour": setShowCaseData('3dvideo')
                    return

                default: setShowCaseData('image')
            }
        }
    }, [locationData.pathname, showCase])

    function setShowCaseData(type) {
        const tempData = showCase?.filter(assets => assets.upload_type === type)
        setCurrentShowCase(tempData)
    }

    const TabsData = [
        {
            id: 1,
            title: "Photos",
            slug: "photos",
        },
        {
            id: 2,
            title: "Floor Plan",
            slug: "floor-plans",
        },
        {
            id: 3,
            title: "Videos",
            slug: "videos",
        },
        {
            id: 4,
            title: "Map",
            slug: "map",
        },
        {
            id: 5,
            title: "Virtual Tour",
            slug: "virtual-tour",
        }
    ]

    return (
        <section className='pt-90'>

            {loading && <CustomLoader />}

            <div className='px-md-5 px-4 py-4 border-bottom mb-5 d-flex align-items-md-center'>
                <div className='property-view-tab text-center py-2'>
                    {
                        TabsData.map(tab => {
                            return (
                                <Link to={`${config.appUrl}property-showcase/${property_type}/${property_id}/${tab.slug}`} key={tab.id}
                                    className={`font-saira ${tab.slug === tab_name && 'active-tab'} mx-2`}>
                                    {tab.title}
                                </Link>
                            )
                        })
                    }
                </div>
                <div className='mt-md-0 mt-2'>
                    <Link to={`${config.appUrl}home-for-${property_type}/property-details/${property_id}`}><i className='bi bi-x-lg fs-4 lh-sm text-dark'></i></Link>
                </div>
            </div>

            <div className='px-md-5 px-4 mb-5'>
                {/* photos */}
                {
                    tab_name === "photos" &&
                    <Photos currentShowCase={currentShowCase} />
                }
                {
                    tab_name === "floor-plans" &&
                    <FloorPlans />
                }
                {
                    tab_name === "videos" &&
                    <Videos currentShowCase={currentShowCase} />
                }
                {
                    tab_name === "map" &&
                    <Map propertyData={currentProperty} />
                }
                {
                    tab_name === "virtual-tour" &&
                    <VirtualTour currentShowCase={currentShowCase} />
                }

                {
                    (currentShowCase?.length === 0) && (tab_name === "photos" || tab_name === "videos") &&
                    <div className='text-center'><h4>No <span className='text-capitalize'>{tab_name}</span> Found!</h4></div>
                }
            </div>
        </section>
    )
}

export default PropertyShowCase