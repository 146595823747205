import React, { useEffect, useState } from "react";
import "../../assets/Css/Neighbourhood.css"
import { Link } from "react-router-dom";
import config from "../../config";
import CityAreaCard from "./CityAreaCard";
import CustomLoader from "../CustomLoader";

const AllNeighbourCities = ({homePageData,loading}) => {

    const [cities, setCities] = useState([])

    const [page, setPage] = useState(1)
    const [items_perpage, setItemsPerPage] = useState(20)

    const [headingData, setHeadingData] = useState({});

    useEffect(() => {

        if(homePageData){
            setCities(
                homePageData?.cities
                    .filter(city => city.city_status == '1')
                    .map(city => ({ ...city, locality_name: city.city_name }))
            )
            setHeadingData(homePageData?.titles[3])
        }
        
    }, [homePageData])

    const positions = [1, 5, 6, 10, 14, 15, 19, 23, 24, 28, 32, 33];

    // pagination logic
    const total_pages = Math.ceil(cities?.length / items_perpage);

    const final_data = cities?.slice(page * items_perpage - items_perpage, page * items_perpage)

    const changePage = (pagecount) => {
        if (pagecount > 0 && pagecount <= total_pages) {
            setPage(pagecount)
            window.scrollTo({top: 0, behavior: 'instant'})
        }
    }

    return (
        <>
            {loading && <CustomLoader />}
            <div className='pt-90'>
                <div className="bd-cites-section">
                    <section >
                        <div className="bd-main-service-section ">
                            <div className="bd-dashed-line mr-10">
                            </div>
                            <div>
                                <p className="bd-service-first-heading text-uppercase">{headingData?.title}</p>
                            </div>
                            <div>
                                <div className="bd-dashed-line ml-10"></div>
                            </div>
                        </div>
                        <div className="textCenter mx-3 ">
                            <h2 className="bd-service-main-heading">Our Cities</h2>
                        </div>
                    </section>

                    <section >
                        <div className="px-5  mt-5" >
                            <div className="row neighbour-city-design">
                                {final_data.map((city, i) => {
                                    return positions.includes(i) ? (
                                        <div key={city.id} className="col-xl-6 col-lg-4 col-sm-6 mb-4">
                                            <Link
                                                to={`${config.appUrl}propuser-locality/${city.id}`}
                                            >
                                                <CityAreaCard city={city} />
                                            </Link>
                                        </div>
                                    ) : (
                                        <div key={city.id} className="col-xl-3 col-lg-4 col-sm-6  mb-4">
                                            <Link
                                                to={`${config.appUrl}propuser-locality/${city.id}`}
                                            >
                                                <CityAreaCard city={city} />
                                            </Link>
                                        </div>
                                    );
                                })}

                                {cities.length == 0 && (
                                    <h2 className="text-secondary text-center">No Locality Found </h2>
                                )}
                            </div>
                        </div>

                        {/* Pagination buttons */}
                        <div className='d-flex justify-content-center flex-wrap py-4 '>

                            <div className={`pagination-btn rounded-2 me-3 mt-2 ${page === 1 && 'opacity-50'}`}
                                onClick={() => changePage(page - 1)}>
                                <i className='bi bi-arrow-left'></i>
                            </div>

                            {
                                [...Array(total_pages)].map((_, pagecount) => {
                                    pagecount += 1
                                    return (
                                        <div key={pagecount} className={`pagination-btn text-center rounded-2 mx-1 mt-2 ${page === pagecount && 'activepage'}`} onClick={() => changePage(pagecount)}>
                                            {pagecount}
                                        </div>
                                    )
                                })
                            }

                            <div className={`pagination-btn rounded-2 ms-3 mt-2 ${(page === total_pages) && 'opacity-50'}`}
                                onClick={() => changePage(page + 1)}>
                                <i className='bi bi-arrow-right'></i>
                            </div>

                        </div>
                    </section>
                </div>

            </div>


        </>
    )

}
export default AllNeighbourCities