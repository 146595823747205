import React, { useEffect, useState } from "react";
import CustomLoader from "../CustomLoader";
import { Link, useNavigate, useParams } from "react-router-dom";
import config, { axiosbaseurl } from "../../config";
import "../../assets/Css/localitynew.css";
import dotsImg from "../../assets/PagesUnderHome/small-dots.png";
import LocalityMapView from "./LocalityMapView";

import { LazyLoadImage } from 'react-lazy-load-image-component';

// https://maps.googleapis.com/maps/api/place/nearbysearch/json?latitude=22.3072&longitude=73.1812
// AIzaSyC7PLiQwZE_yPxmkXkeySsVhGqKPNI-Y58

const LocalityDetailNew = ({ homePageData }) => {
  const [loading, setLoading] = useState(false);
  const { locality_id } = useParams();
  const [localityDetails, setLocalityDetails] = useState(null);
  const [nearHomeSale, setNearHomeSale] = useState([])
  const [otherCities, setOtherCities] = useState(null)

  const navigate = useNavigate()

  useEffect(() => {
    const controller = new AbortController();

    if (locality_id) {
      fetchData()
    }

    return () => {
      controller.abort();
    };
  }, [locality_id, homePageData]);

  const fetchData = async () => {
    try {

      setLoading(true);
      const response = await axiosbaseurl.get(`/locality/${locality_id}`);
      // console.log('locality ', response.data)
      setLocalityDetails(response.data.data);
      setOtherCities(homePageData?.cities?.filter(item => item.id != response.data?.data?.city))

      const latitude = response.data?.data?.latitude
      const longitude = response.data?.data?.logitude

      if (latitude && longitude) {
        const properties = await axiosbaseurl.post('/properties', { user_id: 0 });

        const temp_data =
          properties?.data?.data?.filter(item => (item.property_on === 'Sale' && item.latitude && item.longitude))
            ?.map(prop => (
              {
                locality_name: prop?.locality_name,
                city_name: prop?.city_name,
                zipcode: 201301,
                distance: Math.round(haversineDistance(latitude, longitude, prop?.latitude, prop?.longitude))
              }
            ))

        let unique_near_data = temp_data.reduce((acc, curr) => {
          if (!acc.some(item => item.locality_name === curr.locality_name)) {
            acc.push(curr)
          }
          return acc;
        }, [])

        unique_near_data = unique_near_data.filter(item => item.distance <= 100)

        setNearHomeSale(unique_near_data)

        // console.log('near for sale : ', unique_near_data)
      }



    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  // calculate nearby data
  const haversineDistance = (lat1, lon1, lat2, lon2) => {
    const toRad = (value) => (value * Math.PI) / 180;

    const R = 6371; // Radius of the Earth in km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance in km
  };

  const handleRedirectToProderties = (ptype) => {
    navigate(`${config.appUrl}property/home-for-${ptype}?type=neighbourhood&search_query=${localityDetails?.locality_name}`)
  }

  return (
    <div>
      {loading && <CustomLoader />}

      <section className="pt-90 pb-55" id="Locality-new">
        <div className="container-fluid">
          <div className="row  no-gutters">
            <div
              className="col-md-8"
              style={{
                backgroundImage: `linear-gradient(#111111b0, #111111b0),url(${localityDetails?.image})`,
                // height: "90vh",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="pt-4">
                <h4 className="bd-header-overview bd-header-overview2 text-yellow mb-0">
                  <span className="text-white fs-3 fw-light">Welcome</span> <strong className="fs-3 fw-600">to {localityDetails?.locality_name}</strong>
                </h4>
                <div className="yellow-line">&nbsp;</div>
              </div>
              <div className="d-flex justify-content-md-end justify-content-center pb-4 pe-md-4 mt-5 mt-md-0">
                <div
                  className="card px-3 py-4 col-md-5 col-11 mb-3"
                  style={{ backgroundColor: "#00000055", boxShadow: "0px 3px 6px #F5C95480", borderRadius: '10px' }}
                >
                  <div className="row mb-3">
                    <div className="col-6">
                      <div>
                        <span className="fw-500 text-yellow font-saira fs-6 fs-6">Country</span>
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <span className="fw-500 text-white font-saira fs-6">{localityDetails?.country ? localityDetails?.country : 'India'}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-6">
                      <div>
                        <span className="fw-500 text-yellow font-saira fs-6">State</span>
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <span className="fw-500 text-white font-saira fs-6">{localityDetails?.state ? localityDetails?.state : '--'}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-6">
                      <div>
                        <span className="fw-500 text-yellow font-saira fs-6">Division</span>
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <span className="fw-500 text-white font-saira fs-6">{localityDetails?.division}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-6">
                      <div>
                        <span className="fw-500 text-yellow font-saira fs-6">District</span>
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <span className="fw-500 text-white font-saira fs-6">{localityDetails?.district}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-6">
                      <div>
                        <span className="fw-500 text-yellow font-saira fs-6">District Population</span>
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <span className="fw-500 text-white font-saira fs-6">{localityDetails?.district}</span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row mb-3">
                    <div className="col-6">
                      <div>
                        <span className="fw-500 text-yellow font-saira fs-6">Established</span>
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <span className="fw-500 text-white font-saira fs-6">
                          {moment(localityDetails?.established).format("DD MMM YYYY")}
                        </span>
                      </div>
                    </div>
                  </div> */}
                  <div className="row mb-3">
                    <div className="col-6">
                      <div>
                        <span className="fw-500 text-yellow font-saira fs-6">Coordinates</span>
                      </div>

                    </div>
                    <div className="col-6">
                      <div>
                        <div className="fw-500 text-white font-saira fs-6">
                          {localityDetails?.coordinates?.split(',')[0]}° N <br />
                          {localityDetails?.coordinates?.split(',')?.slice(1)?.join(' ')}° E
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row mb-3">
                    <div className="col-6">
                      <div>
                        <span className="fw-500 text-yellow font-saira fs-6">Area</span>
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <span className="fw-500 text-white font-saira fs-6">{localityDetails?.area} Km</span>
                      </div>
                    </div>
                  </div> */}
                  <div className="row mb-3">
                    <div className="col-6">
                      <div>
                        <span className="fw-500 text-yellow font-saira fs-6">Elevation</span>
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <span className="fw-500 text-white font-saira fs-6">

                          {localityDetails?.elevation}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-6">
                      <div>
                        <span className="fw-500 text-yellow font-saira fs-6">Area code</span>
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <span className="fw-500 text-white font-saira fs-6">{localityDetails?.area}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-6">
                      <div>
                        <span className="fw-500 text-yellow font-saira fs-6">City</span>
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <span className="fw-500 text-white font-saira fs-6">{localityDetails?.district}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-5 pt-md-2">

              <div className="row ps-md-5 ">
                <div className="col-10 p-md-0 ">
                  <div className="mt-md-0 mt-4">
                    <h4 className="font-saira know-about-city mb-0">
                      Know About {localityDetails?.locality_name}
                    </h4>
                    <div className="yellow-line"></div>
                    <div className="bd-info-agent mt-3"
                      dangerouslySetInnerHTML={{ __html: localityDetails?.description }}
                    />
                  </div>
                </div>
                <div className="col-2 locality-dots d-flex align-items-center justify-content-end p-0">
                  <img
                    src={dotsImg}
                    alt="dotsImg"
                    className=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-55">
        <div>
          <div className="d-flex  textCenter mx-4 justify-content-md-center">
            <div>
              <h2 className="text-dark-blue fw-600 font-saira display-6 mb-0">Around the {localityDetails?.locality_name}</h2>
              <div className="yellow-line">&nbsp;</div>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-4">
        <div>
          <div className="mx-4">
            <div>
              <h2 className="text-dark-blue fw-600 font-saira display-6 mb-0">Located in {localityDetails?.locality_name}</h2>
              <div className="yellow-line">&nbsp;</div>
            </div>
          </div>
        </div>
      </section>
      {/* map */}
      <section className="mx-4 pb-55">
            <LocalityMapView
              localityData={localityDetails}
            />
        {/* <div className="position-relative">
          <div className="bd-main-section-map locality_map">
          </div>

          <div className="locality_map_detail p-4 bg-white shadow">
            <h4 className="font-saira fw-600 mb-1 text-dark-blue">Highways</h4>
            <div className="yellow-line mb-3"></div>
            <div>
              <p className="paragraph font-saira mb-1">NH-24 Highway</p>
              <p className="paragraph font-saira mb-1">FNG Expressway</p>
              <p className="paragraph font-saira mb-0">Dadri Highway</p>
            </div>
          </div>

          <div className="locality_map_detail2 p-4 bg-white shadow">
            <h4 className="font-saira fw-600 mb-1 text-dark-blue">Boundaries Near Center Delhi</h4>
            <div className="yellow-line mb-3"></div>
            <div>
              <p className="paragraph font-saira mb-1">North side Delhi National Capital</p>
              <p className="paragraph font-saira mb-1">West side Haryana</p>
              <p className="paragraph font-saira mb-0">East & West Uttar Pradesh</p>
            </div>
          </div>

        </div> */}

      </section>


      <section className="pb-55">
        <div className="mx-4">
          <div className="row mb-5">
            <div className="col-12">
              <div>
                <div className="mb-4">
                  <h2 className="text-dark-blue fw-600 font-saira display-6 mb-0">{localityDetails?.points?.length} Points of any city</h2>
                  <div className="yellow-line">&nbsp;</div>
                </div>
                <div className="ps-md-5 points-10">
                  {
                    localityDetails?.points?.map((point, index) => {
                      return (
                        <div className="d-flex mb-3" key={point?.id}>
                          <div>
                            <h2 className=" font-saira pe-3 text-yellow fw-bolder">
                              {index + 1}
                            </h2>
                          </div>
                          <div>
                            <h3 className="text-dark-blue font-saira">
                              {point?.title}
                            </h3>
                            <div className="yellow-line mb-2">&nbsp;</div>
                            <div>
                              <p className="fs-6 points_desc" dangerouslySetInnerHTML={{ __html: point?.description }}>
                                {/* {point?.description} */}
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="buttons-grroup ps-md-5">
            <div className="ps-md-5">
              <button className="btn text-white me-md-4 py-3 mb-3 mb-md-0"
                onClick={() => handleRedirectToProderties('sale')}
              >
                Homes for Sales in {localityDetails?.locality_name}
              </button>
              <button className="btn text-white py-3"
                onClick={() => handleRedirectToProderties('rent')}
              >
                Homes for Rent in {localityDetails?.locality_name}
              </button>
            </div>
          </div>
        </div>
      </section>

      {
        localityDetails?.points?.slice(0, 5)?.map((point, index) => {
          return (
            <section className="pb-55 mx-4 pt-90 mt-5 point-gallery" key={point.id}>
              <div className="position-relative mb-4">
                <div className={`top-card col-md-6 ${index % 2 !== 0 && 'top-card-2'} `}>
                  <div className="card py-3 px-4 bg-transparent border-0">
                    <span className="text-white display-3 fw-bold font-saira">{(index + 1 < 10) ? ('0' + (index + 1)) : (index + 1)}</span>
                    <h3 className="mb-3 font-saira">{point.title}</h3>
                    <p className="font-saira points_img_desc" dangerouslySetInnerHTML={{ __html: point?.description }}>
                      {/* {point.description} */}
                    </p>
                  </div>
                </div>
                <div className="img-1">
                  <LazyLoadImage
                    src={point.image_one}
                    width={500}
                    effect="black-and-white"
                    alt="AccesiblityImg"
                    className=" w-100"
                  />
                </div>
              </div>
              <div className="row mb-4 point-two-imgs">
                <div className="col-md-6 mb-3 mb-md-0">
                  <LazyLoadImage
                    src={point.image_two}
                    alt=""
                    width={500}
                    effect="black-and-white"
                    className="w-100" />

                </div>
                <div className="col-md-6">
                  <LazyLoadImage
                    src={point.image_three}
                    effect="black-and-white"
                    width={500}
                    alt=""
                    className="w-100" />
                </div>
              </div>
              <div>
                <LazyLoadImage
                  src={point.image_four}
                  effect="black-and-white"
                  alt=""
                  width={500}
                  className="w-100" />
              </div>
            </section>
          )
        })
      }

      <section className="pb-55 mx-4">
        <div className="mb-4">
          <h2 className="text-dark-blue fw-600 font-saira ">Other Neighbourhood Cities</h2>
          <div className="yellow-line mb-3">&nbsp;</div>
        </div>
        <div className="row overlay-card">
          {
            otherCities?.slice(0, 3)?.map(city => {
              return (
                <div className="col-xxl-3 col-md-4 col-sm-6 mb-3 mb-md-0" key={city.id}>
                  <div className="overlay-n position-reletive overflow-hidden">
                    <img
                      src={city.image}
                      alt=""
                      className="img-fluid w-100"
                    />
                    <span className="text-yellow">{city.city_name}</span>

                    <div className="city_icon pe-1">
                      <i className="bi bi-bicycle lh-sm"></i>
                    </div>
                  </div>
                </div>
              )
            })
          }

        </div>
      </section>


      <section className="pb-55 mx-4">
        <div className="mb-5">
          <h2 className="text-dark-blue fw-600 font-saira  ">Homes for Sale Near {localityDetails?.city_name}</h2>
          <div className="yellow-line mb-3">&nbsp;</div>
        </div>
        <div className="row ps-md-5 homes-sales">
          <div className="col-md-4">
            <h3 className="font-saira mb-1">Neighbourhoods</h3>
            <div className="yellow-line mb-4">&nbsp;</div>
            {/* options */}
            <div className="options d-flex flex-column">
              <NearHomeData nearHomeSale={nearHomeSale} type={'locality_name'} neighbourhood={'type=neighbourhood&'} />
            </div>
          </div>
          <div className="col-md-4">
            <h3 className="font-saira mb-1">Cities</h3>
            <div className="yellow-line mb-4">&nbsp;</div>
            {/* options */}
            <div className="options d-flex flex-column">
              <NearHomeData nearHomeSale={nearHomeSale} type={'city_name'} />
            </div>
          </div>
          <div className="col-md-4">
            <h3 className="font-saira mb-1">Pincode</h3>
            <div className="yellow-line mb-4">&nbsp;</div>
            {/* options */}
            <div className="options d-flex flex-column">
              <NearHomeData nearHomeSale={nearHomeSale} type={'zipcode'} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};


const NearHomeData = ({ nearHomeSale, type, neighbourhood = '' }) => {

  const [showAll, setShowAll] = useState(false)
  const [datas, setDatas] = useState([])

  useEffect(() => {
    if (!showAll && nearHomeSale.length > 5)
      setDatas(nearHomeSale?.slice(0, 5))
    else
      setDatas(nearHomeSale)

  }, [nearHomeSale, showAll])

  return (
    <>
      {
        datas.map((item, index) => {
          return (
            <Link to={`${config.appUrl}property/home-for-sale?${neighbourhood}search_query=${item[type]}`} key={index} >{item[type]}</Link>
          )
        })
      }
      {
        nearHomeSale.length > 5 &&
        <a className="fw-600" onClick={() => setShowAll(prev => !prev)}>
          Show {showAll ? 'Less' : 'All'}
          <i className={`bi bi-chevron-${showAll ? 'up' : 'down'} text-yellow ms-2`}> </i>
        </a>
      }
    </>
  )
}

export default LocalityDetailNew;
