import React from 'react'

const FormField = ({ step, head1, head2, children, handleNextStep, handleRegisterUser, loading = false }) => {

    const handleSubmit = () => {
        if (handleRegisterUser) {
            handleRegisterUser()
        } else {
            handleNextStep(children.props.children.props.name)
        }
    }

    return (
        <div >
            <div className="agent-heading">
                <div>{step}</div>
                <div>{head1}</div>
                <h3>{head2}</h3>
            </div>

            {
                children
            }

            <div className='mt-4 pt-2 text-start'>
                <button type='button' onClick={handleSubmit} disabled={loading} className='btn-blue agent-ok-btn ms-0 w-auto py-1 d-flex align-items-center px-3'>
                    {loading ? 'Sending.. ' : 'Ok'}  <i className={`bi bi-${loading ? 'opencollective rotate-icon fs-5 ms-3' : 'check fs-2'}  text-white ms-1`}></i>
                </button>


            </div>
        </div>
    )
}

export default FormField